import AddIcon from '@material-ui/icons/Add';
import ProjectIcon from '@material-ui/icons/Folder';
import SpecificationIcon from '@material-ui/icons/Description';
import { INavBarItem } from './INavBarItem';
import SpecificationCreate from 'Component/SpecificationCreateModal/CreateSpecification';

export const NavBarItems: INavBarItem[] = [
  {
    type: 'dropdown',
    text: 'Skapa',
    icon: AddIcon,
    items: [
      {
        type: 'modal',
        button: {
          type: 'button',
          icon: AddIcon,
          text: 'Nytt projekt',
        },
      },
      {
        type: 'custom',
        text: 'Skapa funktionsbeskrivning',
        component: SpecificationCreate,
      },
    ],
  },
  {
    type: 'divider',
  },
  {
    type: 'path',
    icon: ProjectIcon,
    path: '/projekt',
    text: 'Projekt',
  },
  {
    type: 'path',
    icon: SpecificationIcon,
    path: '/Beskrivningar',
    text: 'Beskrivningar',
  },
  {
    type: 'divider',
  },
];
