import React, { useRef, useState } from 'react';
import { Modal } from 'Component/Modal';
import { Input } from 'Component/Form';
import { useAddParticipant, useCustomForm, useTranslation, useProjectRoles } from 'hooks';
import { Select as CustomSelect } from 'Component/Select';
import './ProjectAddParticipantByEmailModal.scss';
import { ProjectRole } from 'types/IDataContracts';
import RoleDefinitionsModal from './RoleDefinitionsModal';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import InfoIcon from '@material-ui/icons/Info';
import PersonAddIcon from '@material-ui/icons/PersonAdd';


const AddParticipantModalHeader = () => {
  return (
    <div className="d-flex flex-fill flex-column justify-content-center align-items-center participant-add-modal__header">
      <PersonAddIcon className="modal-icon gray-three" />
      <h4 className="modal-title text-primary">{'Lägg till projektmedlem'}</h4>
      <p className="text-center participant-add-modal__text">
        {'Lägg projektmedlem som  bör ha åtkomst till projektet'}
      </p>
    </div>
  );
};

const AccountMissingError = () => {
  return (
    <div className="participant-add-modal__warning">
      <div className="participant-add-modal__warning-body">
        <InfoIcon className="participant-add-modal__warning-icon" />
        <div className="participant-add-modal__warning-text">
          <div className="row">
            <div className="col-12">
              <p>
                Personen du bjuder in saknar konto hos Svensk Byggtjänst. Ett
                användarkonto hos oss är kostnadsfritt, men krävs för att kunna
                nyttja tjänsten. Dela länk för att skapa användarkonto.
              </p>
            </div>
            <div className="link-container col-12 col-md-8">
              https://konto.byggtjanst.se/foretag
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ProjectAddParticipantByEmailModal = ({
  projectId,
}: ProjectAddParticipantByEmailModalProps) => {
  const initialFormValue = {
    mail: '',
    role: 'Member',
  };

  const t = useTranslation();
  const { roles } = useProjectRoles();
  const formRef = useRef<HTMLFormElement | null>(null);
  const [visible, setVisible] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [roleModalVisible, setRoleModalVisible] = useState(false);
  const [val, setVal] = useState(initialFormValue);
  const useAddParticipantMutation = useAddParticipant();

  const addProjectParticipant = async () => {
    try {
      const result = await useAddParticipantMutation.mutateAsync({
        projectId: projectId,
        email: val.mail,
        role: val.role as ProjectRole,
      });

      if (result.success) {
        setVisible(() => false);
        setVal(() => initialFormValue);
      }
    } catch (err) {
      setVisible(() => false);
      setVal(() => initialFormValue);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVal((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
  }

  const handleSelectedChanged = (value: string) => {
    updateValue('role', value);
  };

  const {
    values,
    updateValue,
    handleSubmit,
    handleBlur,
    onSubmitting,
  } = useCustomForm({
    initialValues: val,
    onSubmitHandler: () => {
      return addProjectParticipant();
    },
    formRef: formRef,
  });

  return (
    <Modal
      show={visible}
      setShow={setVisible}
      name={'participant-add-modal'}
      className="participant-add-modal"
      buttonContent={
        <button
          type="button"
          className="btn btn-link d-flex align-items-center">
          <GroupAddIcon className="me-2" /> <span>{'Lägg till'}</span>
        </button>
      }
      header={<AddParticipantModalHeader />}>
      {useAddParticipantMutation.isSuccess && !useAddParticipantMutation.data?.success && <AccountMissingError />}
      <form ref={formRef} onSubmit={handleSubmit}>
        <fieldset disabled={onSubmitting}>
          <div className="form-group participant-add-modal__input-group">
            <h4 className="participant-add-modal__label">{t('Mailadress')}</h4>
            <Input
              ref={inputRef}
              className="participant-add-modal__input"
              type="email"
              name="mail"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values?.mail ?? ''}
            />
          </div>
          <div className="form-group participant-add-modal__input-group">
            <div className="d-flex align-items-end participant-add-modal__info-group">
              <h4 className="participant-add-modal__label">{t('Roll')}</h4>
              <RoleDefinitionsModal
                show={roleModalVisible}
                setShow={setRoleModalVisible}
                roles={roles}
              />
            </div>
            <CustomSelect
              placement="bottom-start"
              offset={[0, 7]}
              options={
                roles
                  .map((r) => ({
                    text: r.roleName,
                    value: r.role.toString(),
                    selected: r.role === values.role.toString(),
                  }))
              }
              onSelectedChanged={handleSelectedChanged}
            />
          </div>
          <div className="d-flex justify-content-end participant-add-modal__footer">
            <button
              type="submit"
              className="btn btn-outline-primary"
              disabled={!values?.mail}>
              {t('Skicka inbjudan')}
            </button>
          </div>
        </fieldset>
      </form>
    </Modal>
  );
};

interface ProjectAddParticipantByEmailModalProps {
  projectId: string;
}


export default ProjectAddParticipantByEmailModal;
