import React from 'react';
import './ListFrame.scss';
import Popover from 'Component/Popover/Popover';

const ListFrame: React.FC<ListFrameProps> = (props) => {
  const {
    title,
    className = '',
    leftButtons = [],
    rightButtons = [],
    mainTitle = false,
    children,
    subTitle,
    toolTipForTitle = false,
  } = props;

  const Title = mainTitle ? 'h1' : 'h4';
  const titleElement = (
    <span className="d-flex align-items-center pe-3">
      <Title className="pe-2 text-24 font-weight-400">{title}</Title>
    </span>
  );
  const titlePlaceHolder = toolTipForTitle ? (
    <Popover
      bubbleRequired={true}
      placement="right-end"
      offset={[54, 1]}
      content={
        <div className={`align-items-center px-3 py-3 me-1`}>{subTitle}</div>
      }>
      {titleElement}
    </Popover>
  ) : (
    ''
  );

  return (
    <div className={'list-frame ' + className}>
      <div className="list-frame__header">
        <div className="d-flex justify-content-between">
          <span className="d-flex align-items-center">
            {toolTipForTitle ? titlePlaceHolder : titleElement}
            {leftButtons}
          </span>
          {rightButtons}
        </div>
        {subTitle && !toolTipForTitle && (
          <div className="form-text">{subTitle}</div>
        )}
      </div>
      {children}
    </div>
  );
};

interface ListFrameProps {
  className?: string;
  mainTitle?: boolean;
  title: string;
  subTitle?: string;
  filterItems?: string[];
  leftButtons?: React.ReactNode[];
  rightButtons?: React.ReactNode[];
  setFilter?: (p: number) => void;
  toolTipForTitle?: boolean;
}

export default ListFrame;
