import TagManager, { DataLayerArgs } from "react-gtm-module";

export interface GTMEvent {
    userId: string;
    event: string;
    category?: string;
    label?: string | undefined;
    value?: number | undefined;
    page?: string;
}


export const init = (trackingId: string, userId: string) => {
    const dataLayer: DataLayerArgs = {
    dataLayer: {userId: userId},
    dataLayerName: 'dataLayer'
    }
    TagManager.dataLayer(dataLayer)
}

/**
* Updates window.dataLayer with event data for GTM
*
* @example sendEvent({ userId: '0123456', eventName: 'signed_up_for_stuff', category: 'main_form', page: 'sign_up'  })
*/
export const sendEvent = (eventDetails: GTMEvent) => {
    const data = { 
        userId: eventDetails.userId,
        event: eventDetails.event,
        category: eventDetails.category
    }

    const dataLayer: DataLayerArgs = {
        dataLayer: data,
        dataLayerName: 'dataLayer'
      }
      
    TagManager.dataLayer(dataLayer);
}