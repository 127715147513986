import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownItemTypeSelector } from 'Component/Dropdown';
import { useTranslation } from 'hooks';
import { logout } from 'Authentication/AuthUserManager';
import ByggtjanstIcon from 'lib/shared-assets/icons/ByggtjanstIcon';
import { AuthContext } from 'Authentication/Auth';
import Button from 'Component/Button/Button';
import './TopBar.scss';
import AvatarProfile from 'Component/Avatar/AvatarProfile';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Popover from 'Component/Popover/Popover';

function TopBar(): React.ReactElement
{
  const authContext = useContext(AuthContext);
  if (!authContext.user)
  {
    return <div />;
  }
  const t = useTranslation();
  const profile = authContext.user.profile;
  const mypagesUrl =
    window.appConfig.BYGGTJANST_MYPAGES ||
    'https://byggtjanst.se/mina-sidor/tjänster';

  return (
    <header
      role="banner"
      className="d-flex justify-content-end align-items-center top-bar">
      <div className="mobile-only">
        <Link to="/">
          <ByggtjanstIcon />
        </Link>
      </div>

      <Popover
        placement="bottom-start"
        offset={[-25, 3.5]}
        delay={1000}
        content={
          <div className="d-flex flex-column align-items-end px-3 py-2">
            <span>
              {profile?.given_name} {profile?.family_name}
            </span>
            <span className="text-muted text-small">{profile?.sub}</span>
          </div>
        }>
        <Dropdown
          className="top-bar-profile d-flex justify-content-end text-medium"
          placement="bottom-end"
          offset={[2.5, 3.5]}
          opener={
            <Button className="btn d-flex justify-content-center align-items-center">
              <AvatarProfile
                hiddenOnMobile={true}
                avatarOptions={{
                  inverted: true,
                  className: 'me-0 me-sm-2 lime s-36-px',
                  participant: {
                    firstName: profile.given_name,
                    lastName: profile.family_name,
                  },
                }}
              />
              <KeyboardArrowDownIcon />
            </Button>
          }>
          <DropdownItemTypeSelector type="info">
            <AvatarProfile
              className="me-3"
              avatarOptions={{
                inverted: true,
                className: 'me-0 me-sm-2 s-36-px',
                participant: {
                  firstName: profile.given_name,
                  lastName: profile.family_name,
                  email: profile.sub,
                  userid: profile.userid,
                },
              }}
            />
          </DropdownItemTypeSelector>
          <DropdownItemTypeSelector type="divider" />
          <a
            href={mypagesUrl}
            target="_blank"
            rel="noreferrer"
            className="lh-dropdown-item">
            {t('Mina Sidor')}
          </a>
          <a
            href="https://byggtjanst.se/"
            target="_blank"
            rel="noreferrer"
            className="lh-dropdown-item">
            Byggtjänst
          </a>
          <DropdownItemTypeSelector type="divider" />
          <Button
            className="text-center fw-bold lh-dropdown-item"
            onClick={logout}>
            {t('Logga ut')}
          </Button>
        </Dropdown>
      </Popover>
    </header>
  );
}

export default TopBar;
