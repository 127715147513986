import React, { useState } from 'react';
import { Modal } from 'Component/Modal';
import FormHeader from './FormHeader';
import { ProjectViewModel } from 'types/IDataContracts';
import ProjectForm from './ProjectForm';
import { useApi, useUpdateProject } from 'hooks';

const EditProjectModal = ({
  project,
  modalTriggerButton,
}: EditFormProps): JSX.Element => {
  const [visible, setVisible] = useState(false);
  const useUpdateProjectMutation = useUpdateProject();
  const api = useApi();

  const handleSubmit = async (data: ProjectViewModel) => {
    await useUpdateProjectMutation.mutateAsync(data);
    setVisible(() => false);
  };

  return (
    <Modal
      show={visible}
      className={'modal-mw-993'}
      setShow={setVisible}
      key="EditForm"
      header={<FormHeader title={'Redigera projekt'} />}
      buttonContent={modalTriggerButton}>
      <ProjectForm
        project={project}
        onSubmitHandler={handleSubmit}
        onFileUploadHandler={api.SendFile}
        submitButtonText={'Klar'}
      />
    </Modal>
  );
};

interface EditFormProps {
  project: ProjectViewModel;
  modalTriggerButton: React.ReactElement;
}

export default EditProjectModal;
