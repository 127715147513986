import CreateIcon from '@material-ui/icons/Create';
import React from 'react';

export interface FormHeaderProps
{
    title: string;
    text: string;
}

const SpecificationModalHeader: React.FC<FormHeaderProps> = ({ title = 'Skapa funktionsbeskrivning', text }) =>
{
    return (
        <div className="specification-form-header d-flex flex-fill flex-column justify-content-center align-items-center ms-3 mt-5 pt-3 pb-3">
            <CreateIcon className="modal-icon gray-three" />
            <h4 className="modal-title text-primary">{title}</h4>
            <p className="text-center mt-2">{text}</p>
        </div>
    );
};

export default SpecificationModalHeader;