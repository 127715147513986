import { Api } from './Api';
import { User } from 'oidc-client';
import
  {
    ApiConfig,
    HttpClient,
    HttpResponse,
    RequestParams,
  } from './HttpClient';
import { ValidationError } from '../types/IDataContracts';

export class ApiClient extends HttpClient<ISecurityData> {
  private _api: Api<ISecurityData>;

  constructor(user: User | null | undefined)
  {
    super(apiConfig());
    if (!user)
    {
      throw new Error('Cannot initialize ApiClient without a user');
    }
    this._user = user;

    this._api = new Api<ISecurityData>(
      apiConfig(window.appConfig.API_V2_BASE_URL)
    );
    this._api.setSecurityData(this.getSecurityData() as ISecurityData);

    this.num = Math.random();
  }
  public num = 0;
  public RefreshUser(user: User): void
  {
    this._user = user;
    this.setSecurityData(this.getSecurityData());
  }

  private _user: User;
  public get User(): User
  {
    return this._user;
  }

  public get Api(): Api<ISecurityData>
  {
    return this._api;
  }

  private getSecurityData()
  {
    return {
      headers: {
        Authorization: 'Bearer ' + this._user.access_token,
      },
    };
  }

  public SendFile = (
    file: File,
    onProgressHandler: (event: ProgressEvent<EventTarget>) => void
  ): Promise<string> =>
  {
    return new Promise((resolve, reject) =>
    {
      const url = `${apiConfig(window.appConfig.API_V2_BASE_URL).baseUrl || ''
        }/api/Files`;

      const xhr = new XMLHttpRequest();
      xhr.open('POST', url);
      xhr.setRequestHeader(
        'Authorization',
        'Bearer ' + this._user.access_token
      );

      xhr.onload = function ()
      {
        if (this.status >= 200 && this.status < 300)
        {
          resolve(JSON.parse(xhr.response));
        } else if (this.status === 400)
        {
          const error = JSON.parse(xhr.response) as ValidationError;
          reject(error);
        } else
        {
          reject({ status: this.status, statusText: xhr.statusText });
        }
      };

      xhr.onerror = function ()
      {
        reject({ status: this.status, statusText: xhr.statusText });
      };

      xhr.upload.onprogress = (event: ProgressEvent<EventTarget>) =>
      {
        return onProgressHandler(event);
      };

      xhr.send(fileToFormData(file));
    });
  };

  public getNotifications = (
    params: RequestParams = {}
  ): Promise<
    HttpResponse<
      { active: boolean; content: string; notificationLevel: string },
      void
    >
  > =>
    this.request<
      {
        active: boolean;
        content: string;
        notificationLevel: string;
      },
      void
    >({
      path: '/api/SystemNotifications',
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
}

function fileToFormData(file: File): FormData
{
  const formData = new FormData();
  formData.append('formFile', file);
  return formData;
}

export interface ISecurityData extends RequestParams
{
  headers: {
    Authorization: string;
  };
}

const apiConfig = (
  baseUrl = process.env.REACT_APP_API_BASE_URL
): ApiConfig<ISecurityData> => ({
  baseUrl: baseUrl,
  securityWorker: (a: ISecurityData | null): RequestParams =>
  {
    return a ?? {};
  },
});
