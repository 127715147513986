import React, { useState, useEffect } from 'react';
import { usePopper } from 'react-popper';
import * as PopperJS from '@popperjs/core';
import './Dropdown.scss';
import DropdownItemSwitch from './DropdownItemSwitch';

function isClickOnMenuComponent(
  popper: HTMLElement | null,
  button: HTMLElement | null,
  ev: MouseEvent | TouchEvent
): boolean {
  return (
    (ev instanceof MouseEvent || ev instanceof TouchEvent) &&
    (ev.target === popper || popper?.contains(ev.target as Node) === true)
  );
}

function Options(props: DropdownProps): PopperJS.Options {
  return {
    modifiers: [
      { name: 'hide', options: { enabled: true } },
      { name: 'offset', options: { offset: props.offset ?? [] } },
      {
        name: 'preventOverflow', options: {
          mainAxis: false,
        }
      },
    ],
    placement: props.placement || 'bottom',
    strategy: props.strategy || 'absolute',
  };
}

const Dropdown: React.FC<DropdownProps> = (props) => {
  const { opener, children } = props;
  const [showPopper, setShowPopper] = useState(false);
  const [button, setButtonRef] = useState<HTMLDivElement | null>(null);
  const [popper, setPopperRef] = useState<HTMLUListElement | null>(null);
  const { styles, attributes } = usePopper(button, popper, Options(props));
  const { className = '' } = props;

  const onHideHandler = (ev: MouseEvent | KeyboardEvent) => {
    if (ev instanceof KeyboardEvent) {
      return ev.key === 'Escape' ? setShowPopper(false) : undefined;
    }

    if (!isClickOnMenuComponent(button, popper, ev)) {
      setShowPopper(false);
    }
  };

  function unBindEvents() {
    window.removeEventListener('click', onHideHandler);
    window.removeEventListener('keydown', onHideHandler);
  }

  useEffect(() => {
    if (showPopper) {
      window.addEventListener('click', onHideHandler);
      window.addEventListener('keydown', onHideHandler);
    } else {
      unBindEvents();
    }

    return unBindEvents;
  });

  const openClass = showPopper ? ' open' : '';

  return (
    <div className={'dropdown ' + className}>
      <div ref={setButtonRef} title={props.title}>
        {React.cloneElement(opener, {
          onClick: () => {
            setShowPopper(!showPopper);
            return Promise.resolve();
          },
        })}
      </div>
      <ul
        className={'dropdown-menu' + openClass}
        ref={setPopperRef}
        style={styles.popper}
        {...attributes.popper}>
        {React.Children.map(children, (child, idx) => {
          if (React.isValidElement(child)) {
            return (
              <DropdownItemSwitch
                idx={idx}
                child={child}
                setShowPopper={setShowPopper}
              />
            );
          }
        })}
      </ul>
    </div>
  );
};

interface DropdownProps {
  opener: React.ReactElement;
  className?: string;
  placement?: PopperJS.Placement;
  strategy?: PopperJS.PositioningStrategy;
  title?: string;
  offset?: number[];
}

export default Dropdown;
