import { RouteComponentProps } from 'react-router-dom';

export interface IRoute {
  path: string;
  resolvedPath?: string;
  name: string;
  description: string;
  nameResolver?: (
    params: Record<string, string>,
  ) => string;
  component: React.FC<RouteComponentProps<Record<string, string>>>;
  exact?: boolean;
  routes?: IRoute[];
  parent?: IRoute;
}

export const combinePaths = (parentPath: string, childPath: string): string =>
  `${parentPath.replace(/\/$/, '')}/${childPath.replace(/^\//, '')}`;

export const buildPaths = (routes: IRoute[], parentPath = ''): IRoute[] =>
  routes.map((route) => {
    const path = combinePaths(parentPath, route.path);

    return {
      ...route,
      path,
      ...(route.routes && { routes: buildPaths(route.routes, path) }),
    };
  });

export const setupParents = (
  routes: IRoute[],
  parentRoute: IRoute | null = null
): IRoute[] =>
  routes.map((route) => {
    const withParent = {
      ...route,
      ...(parentRoute && { parent: parentRoute }),
    };

    return {
      ...withParent,
      ...(withParent.routes && {
        routes: setupParents(withParent.routes, withParent),
      }),
    };
  });

export const flattenRoutes = (routes: IRoute[]): Array<IRoute> => {
  return flatDeep(routes);
};

function flatDeep(arr: IRoute[]): IRoute[] {
  return arr.reduce((acc: IRoute[], val) => {
    return acc.concat(val.routes ? flatDeep(val.routes) : [], [val]);
  }, []);
}

export const generateAppRoutes = (routes: IRoute[]): IRoute[] => {
  return flattenRoutes(setupParents(buildPaths(routes)));
};

export const pathTo = (
  route: IRoute,
  params?: Record<string, string>
): IRoute[] => {
  if (params) {
    route.resolvedPath = Object.keys(params).reduce(
      (path, param) => path.replace(`:${param}`, params[param]),
      route.path
    );
  }

  if (!route.parent) {
    return [route];
  }

  return [...pathTo(route.parent, params), route];
};
