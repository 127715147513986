import React from 'react';
import { DropdownInfo, DropdownDivider, DropdownItem } from './DropdownItemTypes';

const DropdownItemSwitch: React.FC<DropdownItemSwitchProps> = (props) => {
  const { child } = props; 
  const { type } = child.props as { type?: string };

  switch (type) {
    case 'info':
        return <DropdownInfo>{child}</DropdownInfo>;
    case 'divider':
        return <DropdownDivider />;
    default:
        return (
            <DropdownItem
                key={props.idx}
                child={props.child}
                setShowPopper={props.setShowPopper}
            />
        );
  }
};

interface DropdownItemSwitchProps {
    type?: string;
    idx: number;
    child: React.ReactElement;
    setShowPopper: React.Dispatch<React.SetStateAction<boolean>>;
}

export default DropdownItemSwitch;