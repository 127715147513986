import React from 'react'
import { useUser } from 'hooks';
import AddIcon from '@material-ui/icons/Add';
import { NavBarItemProps } from './NavBarItemType/NavBarItemProps';
import {
  NavLink,
  NavPath,
  NavDivider,
  NavGroup,
  NavDropdown,
  NavButton,
  NavCustom,
} from './NavBarItemType';
import CreateProjectModal from 'Component/ProjectForm/CreateProjectModal';
import { sendEvent, GTMEvent } from 'analytics';


const NavBarItem: React.FC<NavBarItemProps> = (props) => {

  const profile = useUser();


  const newProject_onClick = () => {
    if(profile){
      const eventDetails: GTMEvent = {
        userId: profile.userid,
        event: 'create_project',
        category: 'sidenav'
      }
    sendEvent(eventDetails);
    }
  }

  switch (props.item.type) {
    case 'divider':
      return <NavDivider {...props} />;
    case 'path':
      return <NavPath {...props} />;
    case 'link':
      return <NavLink {...props} />;
    case 'button':
      return <NavButton {...props} />;
    case 'dropdown':
      return <NavDropdown {...props} />;
    case 'modal':
      return (
        <CreateProjectModal
          key="createProjectModal"
          modalTriggerButton={
            <button type="button" className="btn lh-dropdown-item dropdown-item">
              <div className="d-flex justify-content-between align-items-center" aria-hidden="true" onClick={() => newProject_onClick()}>
                {<span>Nytt projekt</span>}
                {<AddIcon />}
              </div>
            </button>
          }
        />
      );
    case 'group':
      return <NavGroup {...props} />;
    case 'custom':
      return <NavCustom {...props} />;
    default:
      return <li />;
  }
};

export default NavBarItem;
