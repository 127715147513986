import React, { useState, useEffect, useRef } from 'react';
type BaseType = React.ReactText | null | undefined;
type BaseFormType = Record<string, BaseType>;
type SupportedInputType = HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement;

function useCustomForm<T = BaseFormType>(
  props: UseCustomFormProps<T>
): ICustomForm<T>
{
  const [values, setValues] = useState<T>(props.initialValues);
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [changed, setChanged] = useState({});
  const [onSubmitting, setOnSubmitting] = useState<boolean>(false);
  const [onBlur, setOnBlur] = useState<boolean>(false);
  const isMounted = useRef(false);

  const handleChange = (
    event: React.ChangeEvent<SupportedInputType>
  ) =>
  {
    const { target } = event;
    const { name, value } = target;
    const oldValue: BaseType = values
      ? ((values as unknown) as BaseFormType)[name]
      : null;
    const oldValueIsNumeric = Number(oldValue) === oldValue;
    updateValue(name, oldValueIsNumeric ? Number(value) : value);
  };

  const updateValue = (name: string, value: unknown) =>
  {
    const initialValue: BaseType = props.initialValues
      ? ((props.initialValues as unknown) as BaseFormType)[name]
      : null;
    setChanged((prevState) => ({ ...prevState, [name]: initialValue != value }));
    setValues((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleBlur = (
    event: React.ChangeEvent<SupportedInputType>
  ) =>
  {
    const { target } = event;
    const { name } = target;

    setTouched({ ...touched, [name]: true });
    setErrors({ ...errors });
  };

  const handleSubmit = async (
    event?: React.FormEvent<HTMLFormElement>
  ): Promise<unknown> =>
  {
    if (event)
    {
      event.preventDefault();
    }

    setErrors({ ...errors });
    setOnSubmitting(true);

    const d = await props.onSubmitHandler({ values, errors });
    isMounted.current && setOnSubmitting(false);
    return d;
  };

  useEffect(() =>
  {
    isMounted.current = true;
    setValues(props.initialValues);
    setErrors({});
    setTouched({});
    setOnSubmitting(false);
    setOnBlur(false);
    return () =>
    {
      isMounted.current = false;
    }
  }, [props.initialValues]);

  return {
    values,
    errors,
    touched,
    changed,
    onSubmitting,
    onBlur,
    setErrors,
    updateValue,
    handleChange,
    handleBlur,
    handleSubmit,
  };
}

export default useCustomForm;

interface ICustomForm<T = BaseFormType>
{
  values: T;
  errors: Record<string, string>;
  touched: Record<string, boolean>;
  changed: Record<string, boolean>;
  onSubmitting: boolean;
  onBlur: boolean;
  updateValue: (propertyName: string, value: unknown) => void;
  handleChange: (
    event: React.ChangeEvent<SupportedInputType>
  ) => void;
  handleBlur: (
    event: React.ChangeEvent<SupportedInputType>
  ) => void;
  handleSubmit: (event?: React.FormEvent<HTMLFormElement>) => Promise<unknown>;
  setErrors: (errors: Record<string, string>) => void;
}

interface ISubmitProps<T>
{
  values: T;
  errors: Record<string, unknown>;
}

interface UseCustomFormProps<T = BaseFormType>
{
  initialValues: T;
  onSubmitHandler: (props: ISubmitProps<T>) => Promise<unknown>;
  formRef: React.MutableRefObject<HTMLFormElement | null>;
}
