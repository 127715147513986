import React, { FC } from 'react';
import AMAStudioFIcon from 'lib/shared-assets/icons/AMAStudioF';
import { shortDate } from 'util/FormatDate';
import './SpecificationListItem.scss';
import { FunctionalSpecificationViewModel } from 'types/IDataContracts';
import { useAppConfig, useProjects } from 'hooks';

interface SpecificationListItemProps
{
  specification: FunctionalSpecificationViewModel;
}

const SpecificationListItem: FC<SpecificationListItemProps> = (props) =>
{
  const { specification } = props;
  const config = useAppConfig();
  const { projects } = useProjects();
  if (!projects)
  {
    return <></>;
  }

  const project = projects.find(p => p.id === specification.projectId);
  if (!project)
  {
    return <></>
  }

  const link = `${config.config.AMASTUDIO_URL}/overview/${specification.id}`;

  return (
    <li
      className="list-group-item specification-list-item">
      <div className="d-flex justify-content-between flex-sm-column flex-md-row">
        <div className="d-flex justify-content-between align-items-center">
          <div className="icon-wrapper">
            <AMAStudioFIcon />
          </div>
          <div className="px-4">
            <h5 className="font-weight-700 text-medium lh-21">
              <a
                href={link}
                target="_blank"
                rel="noreferrer">
                {specification && specification.name}
              </a>
            </h5>
          </div>
        </div>
        <div className="d-flex justify-content-around align-items-center list-item-content">
          <div className="project-name">Projekt - {project.name}</div>
          <div className="font-weight-700 text-small mx-auto status-name">
            {specification?.statusName}
          </div>
          <div className="text-small date-text">
            {specification &&
              shortDate(
                new Date(specification.created)
              )}
          </div>
        </div>
      </div>
    </li>
  );
};

export default SpecificationListItem;

