import Modal from './Modal';
import React, { useState } from 'react';
import Button from './Button';
import ChatIcon from '@material-ui/icons/Chat';
import SendIcon from '@material-ui/icons/Send';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Textarea from './TextArea'
import { ICustomForm } from './FeedbackWidget';
import { ModalName, ModalVisibility } from './constants';
import './FeedbackModal.scss';

const FeedbackModal: React.FC<FeedbackModalProps> = ({
  options: {
    title: modalTitle,
    text: modalText,
    className: modalClassName,
    menuItems,
  },
  formOptions: {
    title: formTitle,
    text: formText,
    className: formClassName,
  },
  formRef,
  form: {
    values,
    changed,
    handleChange,
    handleSubmit,
    handleBlur,
  },
}) => {
  const [modalVisibilityMap, setModalVisibilityMap] = useState<Record<string, ModalVisibility>>({
    [ModalName.Menu]: ModalVisibility.None,
    [ModalName.Form]: ModalVisibility.None,
  });
  const [feedbackSubmitted, setFeedbackSubmitted] = useState<FeedbackDto>();
  const [feedbackCreated, setFeedbackCreated] = useState<boolean | null>(null);

  const isChanged = (s: string) => {
    return changed[s] ? ' form-changed' : '';
  };

  const feedbackSubmitDecorator = async (func: () => Promise<unknown>): Promise<void> => {
    try {
      const succeeded = (await func()) as boolean;
      if (succeeded) {
        void setFeedbackCreated(true);
      } else {
        void setFeedbackCreated(false);
      }
    } catch (error) {
      console.error(error);
      void setFeedbackCreated(false);
    }
  }

  const submitClickHandler = (event?: React.FormEvent<HTMLFormElement>): Promise<void> => {
    setFeedbackCreated(null);
    setFeedbackSubmitted(values);

    return feedbackSubmitDecorator(() => handleSubmit(event));
  }

  const retryClickHandler = (data: FeedbackDto, handler?: (data: FeedbackDto) => Promise<boolean>): Promise<void> => {
    return feedbackSubmitDecorator(() => handler?.(data) ?? Promise.resolve(false));
  }

  const backButtonClickHandler = (event?: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    event?.nativeEvent.stopImmediatePropagation();
    void showModalAction('all');
    return Promise.resolve();
  };

  const toggleModalVisibility = (visibility: ModalVisibility): ModalVisibility => {
    return (visibility === ModalVisibility.None || visibility === ModalVisibility.Hidden ? ModalVisibility.Visible : ModalVisibility.Hidden);
  }

  const showModalAction = (modalName: string, value?: ModalVisibility) => {
    const toggledValue = toggleModalVisibility(modalVisibilityMap[modalName]);
    if (modalName === 'all') {
      setModalVisibilityMap({
        ...modalVisibilityMap,
        ...Object.keys(modalVisibilityMap)
          .map(name => ({ [name]: value ?? toggleModalVisibility(modalVisibilityMap[name]) }))
          .reduce((p, c) => ({ ...p, ...c, }))
      });
    } else {
      setModalVisibilityMap({
        ...modalVisibilityMap,
        ...Object.keys(modalVisibilityMap)
          .filter(name => name !== modalName)
          .map(name => ({ [name]: value ? toggleModalVisibility(value) : modalVisibilityMap[modalName] }))
          .reduce((p, c) => ({ ...p, ...c, })),
        [modalName]: value ?? toggledValue,
      });
    }
  };

  return (
    <Modal
      name={'feedback-actions'}
      visibilityMap={modalVisibilityMap}
      showModalAction={showModalAction}
      buttonContent={
        <Button className={`btn btn-feedback btn-feedback${modalVisibilityMap ? '--active' : ''}`}>
          {Object.keys(modalVisibilityMap).some(name => modalVisibilityMap[name]) === false ? (
            <>
              <ChatIcon className="feedback-chat" />
              <span className="feedback-help">?</span>
            </>
          ) : (
            <CloseIcon className="feedback-close" />
          )
          }
        </Button>
      }
      killSwitch={true}
      internalClassModifier="--feedback-action"
      header={
        <div className="d-flex flex-column">
          {modalTitle && (
            <h3 className={`modal-title modal-title--feedback-action ${modalClassName || ''}`}>
              {modalTitle}
            </h3>
          )}
          <p className="modal-header__text">{modalText}</p>
        </div>
      }>
      <div className="feedback-actions">
        {menuItems.map((item, idx) => (
          <div className="feedback-actions__item" key={idx}>
            <h4 className="feedback-actions__item__title">{item.title}</h4>
            {item.text && <p className="feedback-actions__item__text">{item.text}</p>}
            {item.action.type === 'link' && (
              <a
                href={item.action.url}
                target="_blank"
                rel="noreferrer"
                className="btn btn-outline-primary btn--feedback-button"
              >
                <span>{item.action.text}</span>
              </a>
            )}
            {item.action.type === 'modal' && (
              <Modal
                name={'feedback-form'}
                visibilityMap={modalVisibilityMap}
                showModalAction={showModalAction}
                buttonContent={
                  <Button className="btn btn-outline-primary btn--feedback-button">
                    <span className="me-2">{item.action.text}</span><SendIcon />
                  </Button>
                }
                internalClassModifier="--feedback-form"
                header={
                  <div className="d-flex align-items-start">
                    <Button type="button" className={`btn btn-link btn-link--feedback-back`} onClick={backButtonClickHandler}>
                      <ArrowBackIosIcon />
                    </Button>
                    <div className="d-flex flex-column">
                      <h3 className={`modal-title modal-title--feedback-form ${formClassName || ''}`}>{formTitle}</h3>
                      <p className="modal-header__text">{formText}</p>
                    </div>
                  </div>
                }
                footer={
                  <div className="feedback-form-container">
                    <form ref={formRef} onSubmit={submitClickHandler}>
                      <fieldset>
                        <div className="form-group">
                          <Textarea
                            /* eslint-disable-next-line jsx-a11y/no-autofocus */
                            autoFocus={modalVisibilityMap['feedback-form'] === ModalVisibility.Visible}
                            rows={1}
                            className={`form-control${isChanged('description')} form-control--feedback-form`}
                            name="description"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.description}
                          />
                        </div>
                      </fieldset>
                      <div className="feedback-submit">
                        <Button type="submit" className="btn btn-link btn-link--feedback-form">
                          <SendIcon />
                        </Button>
                      </div>
                    </form>
                  </div>
                }
              >
                <div className="feedback-created">
                  <div className="feedback-submit-container">
                    {feedbackSubmitted && (
                      <>
                        <p>{feedbackSubmitted.description}</p>

                        {feedbackCreated === true && (
                          <div className="feedback-submit-container__status status--success">
                            <CheckCircleOutlineIcon /> <span className="feedback-submit-container__status__text">{item.action.statusTexts?.success}</span>
                          </div>
                        )
                        }

                        {feedbackCreated === false && (
                          <div className="feedback-submit-container__status status--error">
                            <ErrorOutlineIcon />
                            <span className="feedback-submit-container__status__text">{item.action.statusTexts?.error}
                              <Button type="button" className="btn btn-link btn-link--error" onClick={() => retryClickHandler(feedbackSubmitted, item.action.retryOptions?.handler)}>
                                {item.action.retryOptions?.text}
                              </Button>
                            </span>
                          </div>
                        )
                        }
                      </>
                    )
                    }
                  </div>
                </div>
              </Modal>
            )}
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default FeedbackModal;


export interface FeedbackModalProps {
  options: FeedbackModalOptions;
  formOptions: FeedbackFormOptions;
  form: ICustomForm<FeedbackDto>;
  formRef: React.MutableRefObject<HTMLFormElement | null>;
}

export interface FeedbackModalOptions {
  title: string;
  text?: string;
  className?: string;
  menuItems: FeedbackMenuItem[];
}

export interface FeedbackMenuItem {
  title: string;
  text?: string;
  action: FeedbackAction;
}

export interface FeedbackAction {
  type: FeedbackActionType;
  url?: string;
  statusTexts?: {
    success: string;
    error: string;
  };
  retryOptions?: {
    text: string;
    handler: (data: FeedbackDto) => Promise<boolean>;
  },
  text: string;
}

export type FeedbackActionType = "link" | "modal";

export interface FeedbackFormOptions {
  title: string;
  text?: string;
  className?: string;
}

export interface FeedbackDto {
  description: string;
  url: string;
  title: string;
}