// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useEffect, useState, useRef } from 'react';
import CheckIcon from '@material-ui/icons/Check';

const Option = <T extends string | number>({
  text,
  value,
  optionSelected,
  onSelect,
  onSelectedChanged,
}: OptionProps<T>): React.ReactElement => {
  const selected = value === optionSelected;
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (optionSelected !== null) {
      timeoutRef.current = setTimeout(() => {
        onSelectedChanged(optionSelected);
      }, 250);
    }
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [optionSelected]);

  const handleClick = (event: React.MouseEvent | React.KeyboardEvent) => {
    event.stopPropagation();
    onSelect(value);
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <li
      className={`dropdown-item sb-select-option ${selected ? 'active' : ''}`}
      onClick={handleClick}
      onKeyPress={handleClick}>
      <div className="d-flex justify-content-between align-items-center">
        <span>
          <span className="sb-select-option__text">{text}</span>
        </span>
        {selected && <CheckIcon className="sb-select-option__icon-selected" />}
      </div>
    </li>
  );
};

interface OptionProps<T> {
  text: string;
  value: T;
  optionSelected: T | null;
  onSelect: (value: T) => void;
  onSelectedChanged: (value: T) => void;
}

export default Option;
