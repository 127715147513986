import React from 'react';
import FolderIcon from '@material-ui/icons/Folder';

const FormHeader: React.FC<FormHeaderProps> = ({ title }) =>
{
  return (
    <div className="d-flex flex-fill flex-column justify-content-center align-items-center ms-3 pt-3">
      <FolderIcon className="modal-icon gray-three mt-4" />
      <h4 className="modal-title text-primary mt-2">{title}</h4>
    </div>
  );
};

interface FormHeaderProps
{
  title: string;
}

export default FormHeader;
