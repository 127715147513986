import
{
  useHasLicense,
  useTranslation,
} from 'hooks';
import React, { FC } from 'react';
import ListFrame from 'Component/ListFrame/ListFrame';
import SpecificationAccordionList from 'Page/Project/SpecificationAccordionList';
import AddIcon from '@material-ui/icons/Add';
import './ProjectSpecificationList.scss';
import { ProjectViewModel } from 'types/IDataContracts';
import SpecificationCreateModal from 'Component/SpecificationCreateModal/SpecificationCreateModal';

const ProjectSpecificationList: FC<SpecificationListProps> = ({
  project,
  title,
  subTitle,
  userRole
}) =>
{
  const t = useTranslation();
  const { hasLicense } = useHasLicense();
  const canAddSpecification = userRole === 'Admin';

  return (
    <ListFrame
      title={title}
      subTitle={subTitle}
      toolTipForTitle={true}
      rightButtons={[
        <SpecificationCreateModal
          projectId={project.id}
          key={'create-fs-modal'}
          buttonContent={
            <button
              type="button"
              disabled={!canAddSpecification}
              className={`btn btn-link d-flex align-items-top py-0 me-0  ${!hasLicense ? 'disabled' : ''} `}>
              {
                <div className="d-flex align-items-center">
                  <AddIcon className="fs-5 me-1 disabled" />{' '}
                  <h5 className="mb-0 fs-6">{t('Skapa')}</h5>
                </div>
              }
            </button>
          }
        />,
      ]
      }>
      <SpecificationAccordionList projectId={project.id} />
    </ListFrame>
  );
};

interface SpecificationListProps
{
  project: ProjectViewModel,
  userRole: string | undefined;
  title: string;
  subTitle: string;
}

export default ProjectSpecificationList;
