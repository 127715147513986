import { Dropdown, DropdownItemTypeSelector } from 'Component/Dropdown';
import React from 'react';
import Button from 'Component/Button/Button';
import { INavBarItem } from '../INavBarItem';
import NavBarItem from '../NavBarItem';
import { NavBarItemProps } from './NavBarItemProps';

const NavDropdown: React.FC<NavBarItemProps> = (props) =>
{
  const IconComponent = props.item.icon;
  const items = props.item.items
    ?.map((item, i) => (
      <NavBarItem key={i} item={item} currentPath={props.currentPath} />
    ))
    .reduce<INavBarItem[]>(
      (p, c) =>
        p.concat([
          c,
          <DropdownItemTypeSelector
            key={`${c.key as string}-divider`}
            type="divider"
          />,
        ]),
      []
    );
  return (
    <li className="navbar-item">
      <Dropdown
        title={props.item.text}
        offset={[0, 3]}
        placement="bottom-start"
        strategy="fixed"
        opener={
          <Button
            className="btn btn-primary dropdown-opener d-flex"
            id="sidenav-create-btn">
            {IconComponent && <IconComponent />}
            <span className="dropdown-btn-text">{props.item.text}</span>
          </Button>
        }>
        {items}
      </Dropdown>
    </li>
  );
};

export default NavDropdown;
