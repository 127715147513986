import React, { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'hooks';
import UploadFiles from 'Shared/FileUpload/UploadFiles';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import './ImageUploader.scss';
import Button from 'Component/Button/Button';
import ProjectImage from './ProjectImage';

const ImageUploader: React.FC<ImageUploaderProps> = ({
  title,
  editInline,
  containImage,
  isChanged,
  imageName,
  updateHandler,
  imageFileId,
  missingText,
  uploadHandler,
  errorHandler,
}) =>
{
  const t = useTranslation();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const deleteHandler = () =>
  {
    updateHandler(imageName, null);
    return Promise.resolve();
  };
  const onUploadedHandler = (fileId: string | undefined) =>
  {
    fileId && updateHandler(imageName, fileId);
    setIsEditing(false);
  };

  const image = (
    <ProjectImage
      title={title}
      containImage={containImage}
      missingText={missingText}
      isChanged={isChanged}
      imageFileId={imageFileId}
      imageName={imageName}
    />
  );

  return (
    <div className="w-100 h-100 position-relative">
      <div className={'d-flex ' + (editInline ? ' image-edit-inline' : '')}>
        {!editInline && <span className="h6 pb-1">{t(title)}</span>}
        <div className="d-flex ps-2 image-edit-buttons">
          {imageFileId && !isEditing && (
            <Button
              className="btn btn-link button-edit"
              onClick={deleteHandler}>
              <DeleteIcon />
            </Button>
          )}
          {imageFileId && !isEditing && (
            <Button
              className="btn btn-link button-edit"
              onClick={() =>
              {
                setIsEditing(true);
                return Promise.resolve();
              }}>
              <EditIcon />
            </Button>
          )}
        </div>
      </div>
      <div className="form-image-container">
        <UploadFiles
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          upload={uploadHandler}
          onUploadedHandler={onUploadedHandler}
          errorHandler={errorHandler}
        />
        {isEditing ? null : imageFileId ? (
          image
        ) : (
          <Button
            className="btn btn-image button-edit w-100 h-100"
            onClick={() =>
            {
              setIsEditing(true);
              return Promise.resolve();
            }}>
            {image}
          </Button>
        )}
      </div>
    </div>
  );
};

interface ImageUploaderProps
{
  title: string;
  editInline?: boolean;
  containImage?: boolean;
  missingText?: string;
  caption?: string;
  imageName: string;
  updateHandler: (propertyName: string, value: unknown) => void;
  isChanged: (n: string) => string;
  uploadHandler: (
    file: File,
    onProgressHandler: (event: ProgressEvent<EventTarget>) => void
  ) => Promise<string>;
  imageFileId?: string | null;
  errorHandler: Dispatch<SetStateAction<string>>;
}

export default ImageUploader;
