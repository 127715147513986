enum ModalName {
    Menu = 'feedback-actions',
    Form = 'feedback-form',
}

enum ModalVisibility {
    None = '',
    Visible = 'show',
    Hidden = 'hide',
}

export { ModalName, ModalVisibility };