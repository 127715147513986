import { UserManager, SignoutResponse } from 'oidc-client';
import { AuthManagerConfig, IAuthConfig } from './AuthManagerConfig';
import { AuthUser } from './AuthUser';

export class AuthUserManager extends UserManager {
  private constructor(config: AuthManagerConfig) {
    super(config);

    this.events.addSilentRenewError((e) => {
      console.log('silent renew error', e.message);
    });

    this.events.addAccessTokenExpired(() => {
      console.log('token expired');
      void this.signinSilent();
    });
  }
  private static instance: AuthUserManager;

  public rr = 0;
  public static init = (config: IAuthConfig): void => {
    if (AuthUserManager.instance === undefined) {
      AuthUserManager.instance = new AuthUserManager(
        new AuthManagerConfig(config)
      );
      AuthUserManager.instance.rr = Math.random();
    }
  };

  static getInstance(): AuthUserManager {
    if (!AuthUserManager.instance) {
      throw new Error(
        'AuthUserManager instance has not been initialized during app startup'
      );
    }

    return AuthUserManager.instance;
  }
}

export const loadUser = (): Promise<AuthUser | null> => {
  return AuthUserManager.getInstance().getUser() as Promise<AuthUser | null>;
};

export const signinRedirect = (): Promise<void> => {
  localStorage.setItem('redirectUri', window.location.pathname);
  return AuthUserManager.getInstance().signinRedirect();
};

export const signinRedirectCallback = async (
  url?: string
): Promise<AuthUser> => {
  return AuthUserManager.getInstance().signinRedirectCallback(
    url
  ) as Promise<AuthUser>;
};

export const signinSilent = async (): Promise<AuthUser> => {
  try {
    const user = await AuthUserManager.getInstance().signinSilent();
    return user as AuthUser;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
export const signinSilentCallback = (): Promise<AuthUser | undefined> => {
  return AuthUserManager.getInstance().signinSilentCallback() as Promise<
    AuthUser | undefined
  >;
};

export const signoutRedirect = async (): Promise<void> => {
  await AuthUserManager.getInstance().clearStaleState();
  await AuthUserManager.getInstance().removeUser();
  return AuthUserManager.getInstance().signoutRedirect();
};

export const signoutRedirectCallback = async (): Promise<SignoutResponse> => {
  await AuthUserManager.getInstance().clearStaleState();
  await AuthUserManager.getInstance().removeUser();
  return AuthUserManager.getInstance().signoutRedirectCallback();
};

export const logout = async (): Promise<void> => {
  await AuthUserManager.getInstance().signoutRedirect({
    id_token_hint: localStorage.getItem('id_token'),
  });
  return AuthUserManager.getInstance().clearStaleState();
};
