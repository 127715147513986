import React, { useState, useEffect, useRef } from 'react';
import { usePopper } from 'react-popper';
import * as PopperJS from '@popperjs/core';
import Option from './Option';
import Button from 'Component/Button/Button';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import CheckIcon from '@material-ui/icons/Check';
import './Select.scss';

function isClickOnMenuComponent(
  popper: HTMLElement | null,
  button: HTMLElement | null,
  ev: MouseEvent | TouchEvent
): boolean
{
  return (
    (ev instanceof MouseEvent || ev instanceof TouchEvent) &&
    (ev.target === popper || popper?.contains(ev.target as Node) === true)
  );
}

function Options<T>(props: SelectProps<T>): PopperJS.Options
{
  return {
    modifiers: [
      { name: 'hide', options: { enabled: true } },
      { name: 'offset', options: { offset: props.offset ?? [] } },
      {
        name: 'preventOverflow',
        options: {
          mainAxis: false,
        },
      },
    ],
    placement: props.placement || 'bottom',
    strategy: props.strategy || 'absolute',
  };
}

const Select = <T extends string | number>(
  props: SelectProps<T>
): React.ReactElement =>
{
  const { options } = props;
  const selectedOption = options.find((o) => o.selected);
  const [showPopper, setShowPopper] = useState(false);
  const [closePopperSignal, setClosePopperSignal] = useState(false);
  const [optionSelected, setOptionSelected] = useState<T | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [button, setButtonRef] = useState<HTMLDivElement | null>(null);
  const [popper, setPopperRef] = useState<HTMLUListElement | null>(null);
  const { styles, attributes } = usePopper(button, popper, Options(props));
  const { className = '' } = props;

  const onHideHandler = (ev: MouseEvent | KeyboardEvent) =>
  {
    if (ev instanceof KeyboardEvent)
    {
      return ev.key === 'Escape' ? setShowPopper(false) : undefined;
    }

    if (!isClickOnMenuComponent(button, popper, ev))
    {
      setShowPopper(false);
    }
  };

  function unBindEvents()
  {
    window.removeEventListener('click', onHideHandler);
    window.removeEventListener('keydown', onHideHandler);
  }

  useEffect(() =>
  {
    if (showPopper)
    {
      window.addEventListener('click', onHideHandler);
      window.addEventListener('keydown', onHideHandler);
    } else
    {
      unBindEvents();
    }

    return unBindEvents;
  });

  useEffect(() =>
  {
    if (showPopper === true && closePopperSignal === true)
    {
      timeoutRef.current = setTimeout(() =>
      {
        setShowPopper(false);
        setClosePopperSignal(false);
      }, 250);
    }
    return () =>
    {
      if (timeoutRef.current)
      {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [showPopper, closePopperSignal]);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) =>
  {
    event.preventDefault();
    event.stopPropagation();

    if (showPopper)
    {
      setClosePopperSignal(true);
    } else
    {
      setShowPopper(true);
    }
    return Promise.resolve();
  };

  const handleSelect = (value: T) =>
  {
    setOptionSelected(value);
  };

  const handleSelectedChanged = (value: T) =>
  {
    props.onSelectedChanged(value);
    setOptionSelected(null);
    setShowPopper(false);
  };

  const openClass = showPopper ? ' open' : '';

  return (
    <div className={`dropdown sb-select ${className}`}>
      <div ref={setButtonRef} title={props.title}>
        <Button
          className={`sb-select__control ${openClass}`}
          onClick={handleClick}>
          <div
            className={`d-flex justify-content-between align-items-center sb-select__selected ${openClass}`}>
            {selectedOption && (
              <span>
                <span className="sb-select__text">{selectedOption.text}</span>
              </span>
            )}
            {!showPopper ? (
              <KeyboardArrowDownIcon />
            ) : (
              optionSelected === null && (
                <CheckIcon className="sb-select__icon-selected" />
              )
            )}
          </div>
        </Button>
      </div>
      <ul
        className={`dropdown-menu ${openClass} sb-select__options`}
        ref={setPopperRef}
        style={styles.popper}
        {...attributes.popper}>
        {options
          .filter((o) => !o.selected)
          .map((o, idx) => (
            <Option
              key={idx}
              text={o.text}
              value={o.value}
              optionSelected={optionSelected}
              onSelect={handleSelect}
              onSelectedChanged={handleSelectedChanged}
            />
          ))}
      </ul>
    </div>
  );
};

interface SelectProps<T>
{
  options: SelectOption<T>[];
  onSelectedChanged: (value: T) => void;
  className?: string;
  placement?: PopperJS.Placement;
  strategy?: PopperJS.PositioningStrategy;
  title?: string;
  offset?: number[];
}

interface SelectOption<T>
{
  text: string;
  value: T;
  selected: boolean;
}

export default Select;
