import React, { useState } from 'react';
import ListFrame from 'Component/ListFrame/ListFrame';
import './List.scss';
import { Add } from '@material-ui/icons/';
import ProjectListItem from './ProjectListItem';
import { useProjects } from 'hooks/projectHooks';
import { ProjectViewModel } from 'types/IDataContracts';
import CreateProjectModal from 'Component/ProjectForm/CreateProjectModal';
import { useTranslation } from 'hooks';
import Skeleton from 'Component/Skeleton/Skeleton';


const filterNames = ['Titel A-Ö', 'Titel Ö-A', 'Senaste', 'Äldsta'];
const filterCompare = (
  a: ProjectViewModel,
  b: ProjectViewModel,
  filter: number
) =>
{
  if (!(a && b)) return 0;
  if (!(a.name && b.name)) return 0;
  if (!(a.created && b.created)) return 0;
  switch (filterNames[filter])
  {
    case 'Titel A-Ö':
      return a.name.localeCompare(b.name);
    case 'Titel Ö-A':
      return b.name.localeCompare(a.name);
    case 'Senaste':
      return b.created.localeCompare(a.created);
    case 'Äldsta':
      return a.created.localeCompare(b.created);
    default:
      return 0;
  }
};


const List = () =>
{
  const [filter, setFilter] = useState(2);
  const { projects, isLoading } = useProjects();
  const t = useTranslation();

  if (isLoading)
  {
    return <Skeleton />
  }

  return (
    <>
      <h1 className="pt-2 mb-4">{t('Projekt')}</h1>
      <p>
        {t('Här listas alla dina projekt.')}
        <br />
        {t('Klicka på "Skapa" om du vill skapa ett nytt projekt.')}
      </p>
      <ListFrame
        className="list-divider"
        title="Alla projekt"
        filterItems={filterNames}
        setFilter={setFilter}
        rightButtons={[
          <CreateProjectModal
            modalTriggerButton={
              <div className="btn btn-link d-flex align-items-center">
                <Add className="pe-1" /> Skapa
              </div>
            }
            key="createproject"
          />,
        ]}>
        {projects && (
          <ul className="list-group">
            {projects
              .sort((a, b) => filterCompare(a, b, filter))
              .map((project) =>
              {
                return <ProjectListItem key={project?.id} project={project} />;
              })}
          </ul>
        )}
      </ListFrame>
    </>
  );
};

export default List;
