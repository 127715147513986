import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import ExpandIcon from '@material-ui/icons/DoubleArrowOutlined';
import ByggtjanstIcon from 'lib/shared-assets/icons/ByggtjanstIcon';
import './NavBar.scss';
import { NavBarItems } from './NavBarItems';
import NavBarItem from './NavBarItem';
import { INavBarItem } from './INavBarItem';
import { useAppConfig, useTranslation } from 'hooks';
import ByggtjanstBrand from 'lib/shared-assets/icons/ByggtjanstBrand';

const NavBar: React.FC = () =>
{
  const [expanded, setExpanded] = useState('expanded');
  const { config } = useAppConfig();
  const [burgerOpen, setBurgerOpen] = useState(false);
  const t = useTranslation();

  function toggleExpanded()
  {
    setExpanded((prevState) => (prevState === 'expanded' ? '' : 'expanded'));
  }
  const closeBurger = () =>
  {
    setBurgerOpen(false);
  };
  const toggleBurger = () =>
  {
    setBurgerOpen(!burgerOpen);
  };

  const currentPath = useLocation().pathname;
  const makeItems = (itemList: INavBarItem[]) =>
  {
    return itemList.map((item, idx) => (
      <NavBarItem
        key={`${item.type} ${idx}`}
        currentPath={currentPath}
        item={item}
        onClick={closeBurger}
      />
    ));
  };

  const items = makeItems(NavBarItems.filter((item) => !item.bottom));
  const bottomItems = makeItems(NavBarItems.filter((item) => item.bottom));

  return (
    <nav role="navigation" className="navbar-container">
      <div className="burger mobile-only">
        <div className="menu-toggle">
          <button
            className={'burger-button' + (burgerOpen ? ' burger-open' : '')}
            onClick={toggleBurger}>
            <span className="burger" />
            <span className="burger" />
            <span className="burger" />
          </button>
          <div className="studio-navbar">
            <ul>{items}</ul>
            <ul>{bottomItems}</ul>
          </div>
        </div>
      </div>
      <div className={'studio-navbar-expand desktop-only ' + expanded}></div>
      <div className="desktop-only">
        <ul
          className={
            'studio-navbar studio-navbar-expand list-unstyled d-flex flex-column justify-content-start align-items-start mb-0 bg-white ' +
            expanded
          }>
          <li className={`navbar-top ${expanded}`}>
            <Link to="/" className="ps-0">
              <ByggtjanstIcon />
            </Link>
          </li>
          {items}
          <li className="position-absolute bottom-0">
            <div className="d-flex align-items-center justify-content-between">
              <span
                className={`expanded-text text-small font-weight-500 font-color-gray-8" ${expanded ? 'order-1' : 'order-2'
                  }`}>
                {t('Minimera menyn')}
              </span>
              <button
                type="button"
                onClick={toggleExpanded}
                className={`btn btn-link navbar-toggler ${expanded} ${expanded ? 'order-2' : 'order-1'
                  }`}>
                <ExpandIcon className={`icon-expand`} />
              </button>
            </div>
            <div className={`bottom-wrapper ${expanded}`}>
              <div className={`bottom-divider ${expanded}`}></div>
              <div className="d-flex flex-column nav-footer">
                <span className="text-9">En del av</span>
                <div className="footer-brand">
                  <a
                    href={config.BYGGTJANST_URL}
                    target="_blank"
                    rel="noreferrer">
                    <ByggtjanstBrand />
                  </a>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default NavBar;
