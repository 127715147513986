import React from 'react';
import { useTranslation } from 'hooks';
import Image from 'Component/Image/Image';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import './ProjectImage.scss';

const ProjectImage: React.FC<ProjectImageProps> = (props) =>
{
  const t = useTranslation();
  const {
    imageFileId,
    imageName,
    containImage,
    isChanged,
    title,
    missingText = t('Bild saknas'),
  } = props;
  return (
    <div
      className={'d-flex w-100 h-100' + (containImage ? ' contain-image' : '')}>
      {imageFileId ? (
        <Image
          className={' ' + isChanged(imageName)}
          alt={t(title)}
          src={imageFileId}
        />
      ) : (
        <div
          className={
            'd-flex flex-column placeholder-image' + isChanged(imageName)
          }>
          <AddCircleIcon className="icon-large" />
          <span className="pt-2">{missingText}</span>
        </div>
      )}
    </div>
  );
};

interface ProjectImageProps
{
  title: string;
  missingText?: string;
  containImage?: boolean;
  imageName: string;
  isChanged: (n: string) => string;
  imageFileId?: string | null;
}

export default ProjectImage;
