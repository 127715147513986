import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { signinSilentCallback } from 'Authentication/AuthUserManager';

const SilentRenew: React.FC<RouteComponentProps> = () => {
  void signinSilentCallback();
  return <span>loading</span>;
};

export default SilentRenew;
