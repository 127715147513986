import { useTranslation } from 'hooks';
import React from 'react';

const Input = React.forwardRef<HTMLInputElement, InputProps>(function Input(
  props,
  ref
)
{
  const {
    label,
    className = '',
    autoFocus,
    placeholder,
    ...otherProps
  } = props;
  const css = `form-control ${className}`;
  const t = useTranslation();

  return (
    <>
      {label && <label className="form-label">{label}</label>}
      {otherProps.required === false && <small> ({t('Valfri')})</small>}
      <input
        /* eslint-disable-next-line jsx-a11y/no-autofocus */
        autoFocus={autoFocus}
        placeholder={placeholder}
        className={css}
        {...otherProps}
        ref={ref}
        onKeyUp={props.onKeyUp}
        onKeyPress={(e) =>
        {
          e.key === 'Enter' && e.preventDefault();
        }}
      />
    </>
  );
});

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement>
{
  label?: string;
  placeholder?: string;
  subLabel?: string;
  className?: string;
  autoFocus?: boolean;
  onKeyUp?: () => void;
}

export default Input;
