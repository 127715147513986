import React, { useEffect } from 'react';
import { loadUser } from './AuthUserManager';
import { ApiClient } from '../api';
import { AuthUser, UserProfile } from './AuthUser';

export const AuthContext = React.createContext<IAuthContext>({
  user: undefined,
  setUser: (p) => {
    return p;
  },
  profile: undefined,
  setProfile: (p) => {
    return p;
  },
  apiClient: undefined,
  setApiClient: (p) => {
    return p;
  },
});
type AuthUserType = AuthUser | null | undefined;
type UserProfileType = UserProfile | null | undefined;
export const Auth: React.FC = (props) => {
  const [user, setUser] = React.useState<AuthUserType>(undefined);
  const [profile, setProfile] = React.useState<UserProfileType>(undefined);
  const [apiClient, setApiClient] = React.useState<ApiClient | undefined>(
    undefined
  );
  useEffect(() => {
    if (user === undefined) {
      void loadUser().then((u) => {
        if (u && !apiClient) {
          setApiClient(new ApiClient(u));
        }
        setUser(u);
      });
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{ user, setUser, profile, setProfile, apiClient, setApiClient }}>
      {props.children}
    </AuthContext.Provider>
  );
};

interface IAuthContext {
  user: AuthUserType;
  setUser: (p: AuthUserType) => void;
  profile: UserProfileType;
  setProfile: (p: UserProfile) => void;
  apiClient: ApiClient | undefined;
  setApiClient: (p: ApiClient) => void;
}
