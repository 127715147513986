import { AuthContext } from 'Authentication/Auth';
import { UserProfile } from 'Authentication/AuthUser';
import { useContext, useEffect, useState } from 'react';

function useProfile(): UserProfile | undefined {
  const { user } = useContext(AuthContext);
  const [profile, setProfile] = useState<UserProfile>();

  if (!user) {
    throw Error('Missing user');
  }

  useEffect(() => {
    setProfile(user.profile);
  }, [user.profile]);

  return profile;
}
export default useProfile;
