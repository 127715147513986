import React, { useEffect, useState, useRef } from 'react';
import './Button.scss';

const Button: React.FC<ButtonProps> = (props) => {
  const { children, onClick, onSubmit, className, ...otherProps } = props;
  const [isLoading, setIsLoading] = useState(false);
  const isMounted = useRef(false);
  const onClickHandler = (ev: React.MouseEvent<HTMLButtonElement>) => {
    if (!onClick) return;
    setIsLoading(true);
    onClick(ev)
      .then((d) => {
        isMounted.current && setIsLoading(false);
        return d;
      })
      .catch((err) => {
        isMounted.current && setIsLoading(false);
        throw err;
      })
  };

  const onSubmitHandler = (ev: React.FormEvent<HTMLButtonElement>) => {
    if (!onSubmit) return;
    setIsLoading(true);
    onSubmit(ev)
      .then((d) => {
        isMounted.current && setIsLoading(false);
        return d;
      })
      .catch((err) => {
        isMounted.current && setIsLoading(false);
        throw err;
      })
  };

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <button
      className={className || 'btn btn-outline-primary'}
      {...otherProps}
      onClick={onClickHandler}
      onSubmit={onSubmitHandler}>
      {isLoading && (
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        />
      )}
      {children}
    </button>
  );
};

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => Promise<unknown>;
  onSubmit?: (event: React.FormEvent<HTMLButtonElement>) => Promise<unknown>;
}

export default Button;
