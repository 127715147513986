import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'hooks';
import Image from 'Component/Image/Image';
import './ProjectCard.scss';
import ProjectCardEmptyIcon from 'lib/shared-assets/icons/ProjectCardEmptyIcon';
import { ProjectViewModel } from 'types/IDataContracts';

const ProjectCard: React.FC<ProjectCardProps> = ({
  project,
  scrollPercentage,
}) =>
{

  const t = useTranslation();

  return (
    <li
      className="card project-card scroll overflow-hidden position-relative"
      style={
        scrollPercentage
          ? { transform: `translateX(-${scrollPercentage}%)` }
          : {}
      }>
      <Link
        className="h-100"
        to={`/projekt/${project?.id || ''}`}
        style={{ zIndex: 2 }}>
        <div className="d-flex flex-column h-100">
          <div className="card-header bg-transparent h-50 border-0 w-100 p-0 overflow-hidden d-flex align-items-center">
            <div className="project-card-image">
              {project.projectImageFileId ? (
                <Image alt={t('Projektbild')} src={project.projectImageFileId} />
              ) : (
                <div className="empty d-flex justify-content-center align-items-center h-100 w-100 fst-italic">
                  <ProjectCardEmptyIcon color="inherit" />
                </div>
              )}
            </div>
          </div>
          <div className="card-body h-50 py-0 overflow-hidden">
            <div className={`d-flex justify-content-between mt-1`}>
              <div className="d-flex flex-column">
                {
                  <p className="text-small text-muted mb-1">
                    {t('Projektnummer')}
                  </p>
                }
                <div className={`text-truncate pe-1 text-small`}>
                  {project?.projectNumber}
                </div>
              </div>
            </div>
            {
              <p className="font-weight-400 h4 mb-0 text-truncate">
                {project?.name}
              </p>
            }
            <p className="text-wrap">{project.description}</p>
          </div>
        </div>
      </Link>
    </li>
  );
};

export interface ProjectCardProps
{
  project: ProjectViewModel;
  scrollPercentage?: number;
}

export default ProjectCard;
