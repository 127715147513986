import AddIcon from '@material-ui/icons/Add';
import InfoIcon from '@material-ui/icons/Info';
import SmsFailedIcon from '@material-ui/icons/SmsFailed';
import { useAppConfig, useTranslation, useProjects, useHasLicense, useUser } from 'hooks';
import React, { useState } from 'react';
import { Modal } from 'Component/Modal';
import './CreateSpecification.scss';
import SpecificationModalHeader from './SpecificationModalHeader';
import SpecificationForm from './SpecificationForm';
import { sendEvent, GTMEvent } from 'analytics';

const CreateSpecification: React.FC = () => {
  const { config } = useAppConfig();
  const { projects } = useProjects();
  const { hasLicense } = useHasLicense();
  const profile = useUser();
  const [modalVisible, setModalVisible] = useState(false);
  const t = useTranslation();
  const hasProjects = projects.length > 0;
  const projectsWhereAdmin = projects.filter(p => p.isAdmin);


  const createSpecificationInfo_onClick = () =>{
    if(profile){
      const eventDetails: GTMEvent = {
        userId: profile.userid,
        event: 'create_specification_info',
        category: 'sidenav'
      }
    sendEvent(eventDetails);
    }
  }

  const iconComponent =
    hasProjects && hasLicense ? (
      <AddIcon />
    ) : (
      <InfoIcon className="text-primary" onClick={() => createSpecificationInfo_onClick()} />
    );

  const buttonText = t('Funktionsbeskrivning');
  const buttonContent =
    hasProjects && hasLicense ? (
      <button
        type="button"
        className={`btn lh-dropdown-item dropdown-item`}>
        <div className="d-flex justify-content-between align-items-center" aria-hidden="true" onClick={() => createSpecificationInfo_onClick()}>
          <span>{buttonText}</span>
          {iconComponent}
        </div>
      </button>
    ) : (
      <div className="d-flex justify-content-between align-items-center">
        <button
          type="button"
          disabled={true}
          className={`btn`}>
          <span>{buttonText}</span>
        </button>
        <button type="button" className="btn">
          {iconComponent}
        </button>
      </div>
    );

  const modalHeader =
    hasProjects && hasLicense ? (
      <SpecificationModalHeader
        title={t('Skapa funktionsbeskrivning')}
        text={t(
          'Beskrivningen måste vara kopplad till ett projekt. Om du inte är administratör i ett befintligt projekt måste du först skapa ett projekt för att sedan skapa din beskrivning.'
        )}
      />
    ) : hasLicense ? (
      <div className="d-flex flex-fill flex-column justify-content-center align-items-center ms-3 pt-5 pb-4">
        <InfoIcon className="modal-icon modal-icon--primary" />
        <h4 className="modal-title text-center">
          {t(
            'För att skapa en funktionsbeskrivning behöver du först skapa ett projekt'
          )}
        </h4>
      </div>
    ) : (
      <div className="d-flex flex-fill flex-column justify-content-center align-items-center ms-3 pt-5 pb-4">
        <SmsFailedIcon className="modal-icon modal-icon--warning" />
        <h4 className="text-center mb-4">
          {t(
            'Du saknar licens. Klicka in dig på länken för att få mer info om tjänsten, så du kan skapa en funktionsbeskrivning'
          )}
        </h4>
        <a
          className="license"
          href={config.MISSING_LICENCE_URL}
          target="_blank"
          rel="noreferrer">
          {config.MISSING_LICENCE_URL}
        </a>
      </div>
    );

  return (
    <Modal
      show={modalVisible}
      setShow={setModalVisible}
      name={'create-specification'}
      scrollable={false}
      header={modalHeader}
      buttonContent={buttonContent}
      className={`${hasProjects && hasLicense ? 'create-specification-modal' : 'create-specification-disabled-modal'}`}>
      {(hasProjects && hasLicense) && (<SpecificationForm projectsWhereAdmin={projectsWhereAdmin} />)}
    </Modal>
  );
};

export default CreateSpecification;
