import React, { ReactElement, useEffect } from 'react';
import { Route, useLocation, matchPath } from 'react-router-dom';
import * as H from 'history';
import { AuthContext } from './Auth';
import { signinRedirect } from './AuthUserManager';

interface PropWithChildren
{
  children: Array<ReactElement<PropWithChildren>>;
}

function isRouteWithMatchingPath(
  child: React.ReactElement<
    PropWithChildren,
    string | React.JSXElementConstructor<unknown>
  >,
  location: H.Location<unknown>
)
{
  return child.type === Route && matchPath(location.pathname, child.props);
}

const findCurrentRoute = (
  parent: Array<ReactElement<PropWithChildren>>,
  location: H.Location<unknown>
): boolean =>
{
  if (!Array.isArray(parent))
  {
    return true;
  }
  for (const child of parent)
  {
    if (isRouteWithMatchingPath(child, location))
    {
      return true;
    }

    if (child.props?.children)
    {
      return findCurrentRoute(child.props.children, location);
    } else if (Array.isArray(child))
    {
      return findCurrentRoute(child, location);
    }
  }
  return false;
};

const AuthProtected: React.FC<PropWithChildren> = ({ children }) =>
{
  const { user } = React.useContext(AuthContext);
  const location = useLocation();
  useEffect(() =>
  {
    if ((user === null || user?.expired) && findCurrentRoute(children, location))
    {
      void signinRedirect();
    }
  }, [user]);

  return user ? <>{children}</> : null;
};

export default AuthProtected;
