import React from 'react';
import './UploadFiles.scss';

const UploadProgress: React.FC<UploadProgressProps> = ({ progress }) => {
  return (
    <div className="progress">
      <div
        className="progress-bar progress-bar-info"
        role="progressbar"
        aria-valuenow={progress}
        aria-valuemin={0}
        aria-valuemax={100}
        style={{ width: `${progress}%` }}>
        {progress}%
      </div>
    </div>
  )
};

interface UploadProgressProps {
  progress: number;
}

export default UploadProgress;
