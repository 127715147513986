import ByggtjanstIcon from 'lib/shared-assets/icons/ByggtjanstIcon';
import React from 'react';
import './loginpage.scss';

const LoginPage: React.FC<LoginPageProps> = (props) => {
  const { icon, children } = props;
  return (
    <div className="loginpage">
        <header className="d-flex justify-content-between align-items-center">
                <div className="byggtjanst-icon desktop-only">
                    <ByggtjanstIcon />
                </div>
            <div className="d-flex align-items-center justify-content-center flex-grow-1">
                <div className="byggtjanst-icon mobile-only">
                    <ByggtjanstIcon />
                </div>
            </div>         
        </header>

        <main role="main" className="fullpage">
            <div className="loginpage-container position-fixed start-50 translate-middle">
                <div className="text-center">
                    <p></p>
                    { !!icon && <h2 className="icon text-primary">{icon}</h2> }
                    <p></p>
                    {children}
                </div>
            </div>
        </main>
    </div>
  );
};

interface LoginPageProps {
  icon?: React.ReactElement;
}

export default LoginPage;
