import React from 'react';
import './LatestSpecifications.scss';
import ListFrame from 'Component/ListFrame/ListFrame';
import SpecificationCard from '../../Component/SpecificationCard/SpecificationCard';
import { useSpecifications } from 'hooks/useSpecifications';
import Skeleton from 'Component/Skeleton/Skeleton';

const LatestSpecifications = () =>
{
  const { specifications, isLoading } = useSpecifications();

  if (isLoading)
  {
    return <Skeleton />
  }

  return specifications.length === 0 ? <></> : (
    <ListFrame title="Senaste beskrivningar" className="mt-5 mb-4">
      <ul className="specifications-list">
        {specifications
          .sort((a, b) => +new Date(b.created) - +new Date(a.created))
          .slice(0, 6)
          .map((spec) => (
            <SpecificationCard key={spec.id} specification={spec} />
          ))}
        <li className="specification-card-spacing"></li>
        <li className="specification-card-spacing"></li>
        <li className="specification-card-spacing"></li>
        <li className="specification-card-spacing"></li>
      </ul>
    </ListFrame>
  );
}

export default LatestSpecifications;
