import React from 'react';
import './Skeleton.scss';

const Skeleton: React.FC<SkeletonProps> = (props) => {
  const { count = 1, circle, height, width } = props;
  const elements = [];

  for (let i = 0; i < count; i++) {
    const style: React.CSSProperties = { height: height, width: width };

    if (circle) {
      style.borderRadius = '50%';
      if (!width && !height) {
        style.width = '1em';
        style.height = '1em';
      }
    }

    elements.push(
      <span key={i} className="skeleton" style={style}>
        &zwnj;
      </span>
    );
  }

  return <>{elements}</>;
};

interface SkeletonProps {
  count?: number;
  circle?: boolean;
  height?: string;
  width?: string;
}

export default Skeleton;
