import useApi from './useApi';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AddParticipantToProjectCommand, AddParticipantToProjectCommandResult, ChangeParticipantRoleCommand, ProblemDetails, RemoveParticipantFromProjectCommand } from 'types/IDataContracts';
import { queryKeys, staleTime } from 'react-query/constants';
import { Notification } from 'Component/Notifications';
import { useNotificationStore } from 'store';

const useUpdateParticipantRole = () => {
    const api = useApi();
    const { setNotification } = useNotificationStore();
    const queryClient = useQueryClient();
    const mutation = useMutation<void, ProblemDetails, ChangeParticipantRoleCommand>(
        async (command) => {
            const response = await api.updateRole(command);
            return response.data;
        },
        {
            onSuccess: async (_, variables) => {
                await queryClient.invalidateQueries([queryKeys.projectParticipants, variables.projectId]);
                setNotification(Notification.Success);
            }
        });
    return mutation;
}

const useAddParticipant = () => {
    const api = useApi();
    const queryClient = useQueryClient();
    const { setNotification } = useNotificationStore();

    const mutation = useMutation<AddParticipantToProjectCommandResult, ProblemDetails, AddParticipantToProjectCommand>(
        async (command) => {
            const response = await api.addParticipant(command);
            return response.data;
        },
        {
            onSuccess: async (data, variables) => {
                if (data.success) {
                    await queryClient.invalidateQueries([queryKeys.projectParticipants, variables.projectId]);
                    setNotification(Notification.Success);
                } else {
                    setNotification(Notification.ServerError);
                }
            },
        });

    return mutation;
}

const useRemoveParticipant = () => {
    const api = useApi();
    const queryClient = useQueryClient();
    const { setNotification } = useNotificationStore();

    const mutation = useMutation<void, ProblemDetails, RemoveParticipantFromProjectCommand>(
        async (command) => {
            const res = await api.deleteParticipant(command);
            return res.data;
        },
        {
            onSuccess: async (_, variables) => {
                await queryClient.invalidateQueries([queryKeys.projectParticipants, variables.projectId]);
                setNotification(Notification.Success);

            }
        });

    return mutation;
}


const useProjectParticipants = (projectId: string) => {
    const api = useApi();
    const { data, isLoading } = useQuery([queryKeys.projectParticipants, projectId],
        async () => {
            const res = await api.projectParticipantsDetail(projectId);
            return res.data;
        }, {
        staleTime: staleTime.oneMinute,
    });

    const participants = data ?? [];

    return {
        participants,
        isLoading
    }
}

export { useUpdateParticipantRole, useAddParticipant, useRemoveParticipant, useProjectParticipants };