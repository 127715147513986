const zeroPad = (n: number, l: number): string => `0000${n}`.slice(-l);

const formatDate = (date: Date, format: string): string =>
{
  const substitutions = {
    YYYY: (d: Date): string => String(d.getFullYear()),
    YY: (d: Date): string => String(d.getFullYear()).slice(-2),
    MM: (d: Date): string => zeroPad(d.getMonth() + 1, 2),
    M: (d: Date): string => String(d.getMonth() + 1),
    DD: (d: Date): string => zeroPad(d.getDate(), 2),
    D: (d: Date): string => String(d.getDate()),
    hh: (d: Date): string => zeroPad(d.getHours(), 2),
    mm: (d: Date): string => zeroPad(d.getMinutes(), 2),
    ss: (d: Date): string => zeroPad(d.getSeconds(), 2),
  };
  let result = format;
  Object.entries(substitutions).forEach(
    ([token, f]) =>
    {
      if (result.includes(token))
      {
        result = result.replace(new RegExp(token, 'g'), f(date));
      }
    }
  );
  return result;
}

const shortDate = (d: Date): string =>
{
  const daysBack = (Date.now() - d.getTime()) / (24 * 3600 * 1000);
  if (daysBack < 1) return formatDate(d, 'hh:mm');
  else if (daysBack < 364) return formatDate(d, 'DD/MM');
  else return formatDate(d, 'YYYY');
}

const fullDate = (d: Date): string => formatDate(d, 'YYYY-MM-DD');

const fullDateTime = (d: Date): string => formatDate(d, 'YYYY-MM-DD hh:mm:ss');

export { formatDate, shortDate, fullDate, fullDateTime };
