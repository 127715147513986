import React, { SetStateAction } from 'react';
import { useUser } from 'hooks';
import { Modal } from '../Modal';
import InfoIcon from '@material-ui/icons/Info';
import RoleSpecificationIcon from 'lib/shared-assets/icons/RoleSpecificationIcon';
import { RoleDescriptionViewModel } from 'types/IDataContracts';
import { sendEvent, GTMEvent } from 'analytics';

interface RoleDefinitionsModalProps {
  show: boolean;
  setShow: React.Dispatch<SetStateAction<boolean>>;
  roles: RoleDescriptionViewModel[] | undefined;
}

const RoleDefinitionsModal = ({
  show,
  setShow,
  roles,
}: RoleDefinitionsModalProps): JSX.Element => {
  const profile = useUser();

  const filteredRoles = (): JSX.Element[] | undefined => {
    return roles
      ?.sort((x, y) => x.role.localeCompare(y.role))
      .map((r) => (
        <div key={r.role} className="project-role-specification__row py-4">
          <span className="project-role-specification__col">{r.roleName}</span>
          <span className="project-role-specification__col">
            {r.description}
          </span>
        </div>
      ));
  };

  const addParticipant_onclick = () =>{
    if(profile){
      const eventDetails: GTMEvent = {
        userId: profile.userid,
        event: 'view_role_info',
        category: 'invite_participant'
      }
    sendEvent(eventDetails);
    }
  }

  return (
    <Modal
      show={show}
      setShow={setShow}
      name={'project-role-specification'}
      className="project-role-specification mb-3"
      buttonContent={
        <button type="button" className="participant-add-modal__button">
          <InfoIcon className="participant-add-modal__icon" onClick={() => addParticipant_onclick()} />
        </button>
      }
      header={
        <div className="d-flex flex-fill flex-column justify-content-center align-items-center participant-add-modal__header">
          <RoleSpecificationIcon className="modal-icon gray-three" />
          <h4 className="modal-title text-primary mb-3">{'Behörighet'}</h4>
        </div>
      }>
      <div className="project-role-specification__content">
        <div className="project-role-specification__body">
          {filteredRoles()}
        </div>
      </div>
    </Modal>
  );
};

export default RoleDefinitionsModal;
