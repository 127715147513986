import React from 'react';
import Button from "Component/Button/Button";
import { NavBarItemProps } from "./NavBarItemProps";

const NavButton: React.FC<NavBarItemProps> = (props) =>
{
    const IconComponent = props.item.icon;
    return (
        <Button className="btn d-flex justify-content-between align-items-center lh-dropdown-item dropdown-item">
            <span>{props.item.text}</span>
            {IconComponent && <IconComponent />}
        </Button>
    );
}

export default NavButton;
