import { useEffect, useRef } from 'react';

const useTimeout = (callback: () => void, delay: number, deps: Array<unknown> = []): void => {
    const savedCallback = useRef<() => void>();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        function tick() {
            savedCallback.current?.();
        }

        const id = setTimeout(tick, delay);
        return () => clearTimeout(id);
    }, [delay, ...deps]);
};

export default useTimeout;