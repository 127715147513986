import React, { useRef, useEffect, useState } from 'react';
import { useTranslation } from 'hooks';
import { Textarea, Input } from 'Component/Form';
import ImageUploader from './ImageUploader';
import LogoUploader from './LogoUploader';
import Button from 'Component/Button/Button';
import './ProjectForm.scss';
import useCustomForm from 'hooks/useCustomForm';
import { ProjectViewModel } from 'types/IDataContracts';

interface ProjectFormProps
{
  project?: ProjectViewModel;
  onSubmitHandler: (data: ProjectViewModel) => void;
  onFileUploadHandler: (
    file: File,
    onProgressHandler: (event: ProgressEvent<EventTarget>) => void
  ) => Promise<string>;
  submitButtonText: string;
}

const nameRegex = new RegExp(/^[\w0-9åäöÅÄÖ\s\\.\\,\\+\\-]*$/);
const ProjectForm: React.FC<ProjectFormProps> = (props) =>
{
  const [fileUploadErrorMessage, setFileUploadErrorMessage] = useState<string>('');
  const t = useTranslation();
  const currentProject = props.project;
  const [
    projectNameHasValidCharacters,
    setProjectNameHasValidCharacters,
  ] = useState(true);
  const formRef = useRef<HTMLFormElement | null>(null);
  const projectNameInputRef = useRef<HTMLInputElement | null>(null);
  const {
    values,
    changed,
    handleChange,
    handleSubmit,
    handleBlur,
    updateValue,
    onSubmitting,
  } = useCustomForm({
    initialValues: currentProject,
    onSubmitHandler: (formData) =>
    {
      if (!formData.values)
      {
        throw new Error('form is empty');
      }

      return Promise.resolve(props.onSubmitHandler(formData.values));
    },
    formRef: formRef,
  });

  const saveDisabled = () =>
  {
    if (!values)
    {
      return true;
    }

    return !projectNameHasValidCharacters || values.name?.length === 0;
  };

  useEffect(() =>
  {
    if (projectNameInputRef && projectNameInputRef.current)
    {
      projectNameInputRef.current.focus();
    }
  }, []);

  const isChanged = (s: string) =>
  {
    return changed[s] ? ' form-changed' : '';
  };


  const nameInputBlur = (e: React.FocusEvent<HTMLInputElement>) =>
  {
    handleBlur(e);


    if (e.target.name === 'name')
    {
      if (!values) return;

      if (values.name.length === 0)
      {
        setProjectNameHasValidCharacters(true);
        return;
      }

      if (nameRegex.exec(values.name))
      {
        setProjectNameHasValidCharacters(true);
      } else
      {
        setProjectNameHasValidCharacters(false);
      }
    }
  };

  return (
    <>
      <form className="project-form" onSubmit={handleSubmit} ref={formRef}>
        <fieldset disabled={onSubmitting}>
          <div className="project-info-top d-flex">
            <div className="project-name d-flex flex-column flex-fill">
              <Input
                label={t('Projektnamn')}
                placeholder={t('Namnge ditt projekt')}
                type="text"
                className={'project-input ' + isChanged('name')}
                name="name"
                onChange={handleChange}
                onBlur={nameInputBlur}
                value={values?.name ?? ''}
                required
                maxLength={17}
              />
            </div>

            <div className="project-info-group d-flex flex-fill justify-content-evenly">
              <div className="project-number d-flex flex-fill flex-column justify-content-center">
                <Input
                  label={t('Projektnr')}
                  placeholder={t('Fyll i projektnr')}
                  type="text"
                  className={'project-input ' + isChanged('projectNumber')}
                  name="projectNumber"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.projectNumber ?? ''}
                  maxLength={15}
                />
              </div>

              <div className="w-25">
                <LogoUploader
                  title="Logotyp för beskrivningar"
                  containImage={true}
                  imageFileId={values?.logotypeFileId}
                  missingText="välj bildlogga"
                  imageName="logotypeFileId"
                  isChanged={isChanged}
                  updateHandler={updateValue}
                  errorHandler={setFileUploadErrorMessage}
                  uploadHandler={props.onFileUploadHandler}
                />
              </div>
            </div>
          </div>

          <div className="project-info-bottom">
            <div className="d-flex flex-column flex-fill project-form-image">
              <label className="form-label">{t('Projektbild')}</label>
              <ImageUploader
                title={t('Omslagsbild')}
                editInline={true}
                imageFileId={values?.projectImageFileId}
                missingText={t('Välj bild som definierar ditt projekt')}
                isChanged={isChanged}
                imageName="projectImageFileId"
                updateHandler={updateValue}
                uploadHandler={props.onFileUploadHandler}
                errorHandler={setFileUploadErrorMessage}
              />
            </div>
            <div className="d-flex flex-column flex-fill project-form-description">
              <Textarea
                placeholder="Beskriv kort vad projektet handlar om"
                label={t('Projektbeskrivning')}
                rows={5}
                className={
                  'project-input resize-none' + isChanged('description')
                }
                name="description"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values?.description ?? ''}
                maxLength={250}
              />
            </div>
          </div>
          <div className="project-form-buttons mt-5 d-flex justify-content-end">
            <Button
              className={'btn btn-outline-primary submit--btn'}
              disabled={saveDisabled()}
              type="submit">
              {t(props.submitButtonText)}
            </Button>
          </div>
        </fieldset>
      </form>
      {!projectNameHasValidCharacters && (
        <span className="text-danger">Godkända tecken är abcd1234,.:-</span>
      )}
      {fileUploadErrorMessage && (
        <span className="text-danger">{fileUploadErrorMessage}</span>
      )}
    </>
  );
};

export default ProjectForm;
