import { ModalVisibility } from './constants';
import React, { useRef, useEffect } from 'react';

function isDescendant(child: HTMLElement | null, parent: HTMLElement | null): boolean {
  let element = child;
  while (element != null) {
    if (element.isSameNode(parent)) {
      return true;
    }

    element = element.parentElement;
  }
  return false;
}

function isClickOnDialogComponent(
  dialog: HTMLElement | null,
  ev: MouseEvent | TouchEvent
): boolean {
  return (
    (ev instanceof MouseEvent || ev instanceof TouchEvent) &&
    (ev.target === dialog || isDescendant(ev.target as HTMLElement, dialog) === true)
  );
}

const ModalDialog: React.FC<ModalDialogProps> = ({
  name,
  visibilityMap,
  header,
  footer,
  showModalAction,
  internalClassModifier,
  className,
  children,
}) => {
  const dialogRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (visibilityMap) {
      window.addEventListener('click', onHideHandler);
      window.addEventListener('keydown', onHideHandler);
    } else {
      unBindEvents();
    }
    return unBindEvents;
  }, [visibilityMap]);

  const onHideHandler = (ev: MouseEvent | KeyboardEvent) => {
    if (visibilityMap[name] !== ModalVisibility.Visible) {
      return;
    }

    if (ev instanceof KeyboardEvent) {
      return ev.key === 'Escape' ? showModalAction('all', ModalVisibility.None) : undefined;
    }

    if (!isClickOnDialogComponent(dialogRef.current, ev)) {
      return showModalAction('all', ModalVisibility.None);
    }
  };

  const unBindEvents = (): void => {
    window.removeEventListener('click', onHideHandler);
    window.removeEventListener('keydown', onHideHandler);
  }

  return (
    <div
      className={`${visibilityMap[name]} modal fade modal${internalClassModifier || ''} ${className || ''}`}
      tabIndex={-1}
      role="dialog">
      <div className={`modal-dialog modal-dialog-bottom modal-dialog${internalClassModifier || ''} ${className || ''}`}>
        <div ref={dialogRef} className={`modal-content modal-content${internalClassModifier || ''} ${className || ''}`}>
          <div className={`modal-header modal-header${internalClassModifier || ''} ${className || ''}`}>
            {header}
          </div>
          <div className={`modal-body modal-body${internalClassModifier || ''} ${className || ''}`}>{children}</div>
          {footer && (
            <div className={`modal-footer modal-footer${internalClassModifier || ''} ${className || ''}`}>
              {footer}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalDialog;

interface ModalDialogProps {
  name: string;
  visibilityMap: Record<string, ModalVisibility>;
  showModalAction: (modalName: string, value?: ModalVisibility) => void;
  header: React.ReactElement;
  footer?: React.ReactElement;
  internalClassModifier?: string;
  className?: string;
}
