import React, { useEffect, useState } from 'react';
import { Modal } from 'Component/Modal';
import CreateSpecificationIFrame from './CreateSpecificationIFrame';
import { useAppConfig, useTranslation } from 'hooks';
import SpecificationModalHeader from './SpecificationModalHeader';
import { TemplateInformationModal } from './TemplateInformationModal';
// import { TemplateInformationModal } from './TemplateInformationModal';

const SpecificationCreateModal: React.FC<SpecificationCreateModalProps> = ({
  projectId,
  buttonContent,
}) => {
  const t = useTranslation();
  const { config } = useAppConfig();
  const [modalVisible, setModalVisible] = useState(false);
  const handleFunctionalSpecificationCreated = (evt: MessageEvent) => {
    const data = evt.data as IFunctionalSpecificationCreated;
    if (data.status === 'created' && data.success && data.id) {
      window.open(`${config.AMASTUDIO_URL}/overview/${data.id}`, '_self');
    } else if (data.status === 'cancelled') {
      setModalVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener('message', handleFunctionalSpecificationCreated);
    return () =>
      window.removeEventListener(
        'message',
        handleFunctionalSpecificationCreated
      );
  }, []);

  return (
    <>
      <Modal
        show={modalVisible}
        setShow={setModalVisible}
        key="create-modal"
        name="fs-create"
        className={`modal--ama-fs-create`}
        header={
          <SpecificationModalHeader
            title={t('Skapa funktionsbeskrivning')}
            text={t(
              'Forsätt skapa din funktionsbeskrivning genom att fylla i informationen och välja vilken kravmall du använda'
            )}
          />
        }
        buttonContent={buttonContent}>
        <CreateSpecificationIFrame projectId={projectId} />
      </Modal >
      <TemplateInformationModal />
    </>

  );
};

interface SpecificationCreateModalProps {
  projectId: string | undefined;
  buttonContent: string | React.ReactElement;
}

interface IFunctionalSpecificationCreated {
  status: 'cancelled' | 'created' | 'failed';
  success?: boolean;
  id?: string;
}

export default SpecificationCreateModal;
