import React, { useState } from 'react';
import { useRemoveParticipant, useTranslation } from 'hooks';
import { Modal } from '../Modal';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from 'Component/Button/Button';
import { IProjectParticipantViewModel } from 'types/IDataContracts';

const ParticipantDeleteModal: React.FC<DeleteParticipantModalProps> = ({
  projectId,
  participant,
}) =>
{
  const [modalVisible, setModalVisible] = useState(false);
  const t = useTranslation();
  const deleteUserMutation = useRemoveParticipant();

  const handleDeleteParticipantSubmit = (event: React.FormEvent<HTMLFormElement>) =>
  {
    event.preventDefault();

    deleteUserMutation.mutate({
      projectId: projectId,
      userId: participant.userId,
    });

    setModalVisible(false);
  };

  return (
    <Modal
      show={modalVisible}
      className={'modal-mw-993'}
      setShow={setModalVisible}
      name={'DeleteParticipantModal'}
      header={
        <div className="d-flex flex-fill flex-column justify-content-center align-items-center participant-edit-modal__header">
          <DeleteIcon className="modal-icon gray-three" />
          <h4 className="modal-title text-primary pt-2">
            {t(
              `Vill du verkligen ta bort ${participant.firstName + ' ' + participant.lastName
              } från projektet?`
            )}
          </h4>
          <p className="text-center participant-edit-modal__text pt-2">
            {t(
              'Du tar nu bort en projektmedlem. Medlemmen kommer inte längre ha tillgång till något innehåll i projektet och kommer inte ha tillgång till någon funktionsbeskriving där personen har deltagit.'
            )}
          </p>
        </div>
      }
      buttonContent={
        <div className="btn btn-link participant-accordion-item__button">
          <DeleteIcon />
        </div>
      }>
      <div className="d-flex justify-content-end participant-edit-modal__footer">
        <form onSubmit={handleDeleteParticipantSubmit}>
          <Button type="submit" className="btn btn-outline-primary">
            {t('Radera nu')}
          </Button>
        </form>
      </div>
    </Modal>
  );
};

interface DeleteParticipantModalProps
{
  projectId: string;
  participant: IProjectParticipantViewModel;
}

export default ParticipantDeleteModal;
