import { useQuery } from 'react-query';
import { queryKeys } from 'react-query/constants';
import useApi from './useApi';

const useHasLicense = () =>
{
  const api = useApi();

  const { data } = useQuery([queryKeys.hasLicense], api.licenceAmaFunktionStudioList, {
    staleTime: Infinity,
    refetchOnWindowFocus: false
  });
  const hasLicense = data?.data;
  return {
    hasLicense
  }
}

export { useHasLicense };
