import { useTranslation } from 'hooks';
import React from 'react';

const Textarea: React.FC<TextareaProps> = (props) =>
{
  const { label, className, ...otherProps } = props;
  const css = `form-control ${className || ''}`;
  const t = useTranslation();

  return (
    <>
      {label && <label className="form-label">{label}</label>}
      {otherProps.required === false && <small> ({t('Valfri')})</small>}
      <textarea className={css} {...otherProps} />
    </>
  );
};

interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement>
{
  label?: string;
}

export default Textarea;
