import React from 'react';

const DropdownItem: React.FC<DropdownItemProps> = (props) => {
  const { child, setShowPopper } = props;
  const childProps = child.props as React.HTMLAttributes<unknown>;
  const className = `${childProps?.className || ''} dropdown-item`;
  const onClickHandler = () => {
    setShowPopper(false);
  };

  const newElement = React.cloneElement(child, {
    className: className,
  });
  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <li onClick={onClickHandler} onKeyPress={onClickHandler}>
      {newElement}
    </li>
  );
};

interface DropdownItemProps {
  child: React.ReactElement;
  setShowPopper: React.Dispatch<React.SetStateAction<boolean>>;
}

export default DropdownItem;
