import React from 'react';
import NavBarItem from '../NavBarItem';
import { NavBarItemProps } from './NavBarItemProps';

const NavGroup: React.FC<NavBarItemProps> = (props) => {
  return (
    <>
      <li className="group navbar-item">
        <small className="text-nowrap">{props.item.text}</small>
      </li>
      {props.item.items?.map((item) => (
        <NavBarItem
          key={item.text}
          item={item}
          currentPath={props.currentPath}
        />
      ))}
    </>
  );
};

export default NavGroup;
