import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ListFrame from 'Component/ListFrame/ListFrame';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import ProjectCard from './ProjectCard';
import './ProjectCardList.scss';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIosSharp';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIosSharp';
import { Button } from '@material-ui/core';
import { useProjects, useUser } from 'hooks';
import ProjectCardEmptyIcon from 'lib/shared-assets/icons/ProjectCardEmptyIcon';
import Skeleton from 'Component/Skeleton/Skeleton';
import CreateProjectModal from 'Component/ProjectForm/CreateProjectModal';
import { sendEvent, GTMEvent } from 'analytics';

const NoProjects = () => {
  const profile = useUser();

  const createProject_onClick = () =>{
    if(profile){
      const eventDetails: GTMEvent = {
        userId: profile.userid,
        event: 'create_project',
        category: 'homepage'
      }
    sendEvent(eventDetails);
    }
  }

  return (
    <div className="row">
      <div className="col-12 col-md-5">
        <div className="card project-card-empty d-flex flex-column align-items-center justify-content-center pb-4">
          <ProjectCardEmptyIcon />
          <p>
            Tyvärr så är du inte med i något projekt än.
          </p>
          <CreateProjectModal modalTriggerButton={(
            <div className="d-flex align-items-top py-0">
              <button type="button" className="btn btn-outline-primary" onClick={() => createProject_onClick()}>
                Skapa
              </button>
            </div>)} />
        </div>
      </div>
    </div >
  );
}

const scroll = 200;
const maxPageCount = 4;
const itemsPerPage = 2;

const ProjectCardList = () => {
  const { projects, isLoading } = useProjects();
  const [state, setState] = useState({
    scrollPercentage: 0
  });

  if (isLoading) {
    return <Skeleton />
  }

  const projectsSortedByCreatedDate = projects
    .sort((a, b) => +new Date(b.created) - +new Date(a.created))
    .slice(0, 8);

  if (projectsSortedByCreatedDate.length === 0) {
    return <NoProjects />;

  }
  const scrollTo = (percentage: number) => {
    setState((prevState) => ({
      ...prevState,
      scrollPercentage: percentage,
    }));
  }

  const goToPage = (pageNumber: number) => {
    setState((prevState) => ({
      ...prevState,
      scrollPercentage: pageNumber === 0 ? 0 : pageNumber * scroll,
    }));
  };

  const getPageNumber = () => {
    return state.scrollPercentage / scroll;
  }

  const projectLength = projectsSortedByCreatedDate.length;

  const pageCount = projectLength < 7
    ? projectLength < 5
      ? projectLength < 3
        ? 0
        : maxPageCount - 2
      : maxPageCount - 1
    : maxPageCount;

  const paginationList = Array.from({ length: pageCount }, (_, i) => (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <li
      onClick={() => goToPage(i)}
      onKeyPress={() => goToPage(i)}
      className={`cursor-pointer ${Math.floor(getPageNumber()) === i ? 'active' : ''
        }`}
      key={`{project_${i}}`}
    />
  ));

  return (
    <ListFrame
      title={'Mina senaste projekt'}
      rightButtons={
        projectsSortedByCreatedDate.length === 0
          ? []
          : [
            <Link
              key="projekt/"
              className="d-flex align-items-top py-0 me-0 link-all-project"
              to="projekt/">
              <FormatListBulletedIcon className="me-1" />{' '}
              <h5> Se alla projekt</h5>
            </Link>,
          ]
      }>
      <ul
        className="project-card-list scroll ps-0 d-flex position-relative">
        {getPageNumber() > 0 && (
          <li className="projects-showmore scroll scroll-left d-flex align-items-center">
            <Button
              className="btn-scroll"
              onClick={() => scrollTo(state.scrollPercentage - scroll)
              }>
              <ArrowBackIosIcon className="projects-showmore-icon" />
            </Button>
          </li>
        )}
        {projectsSortedByCreatedDate.map((project) => {
          return (
            <ProjectCard
              key={project.id}
              project={project}
              scrollPercentage={state.scrollPercentage}
            />
          );
        })}
        {projectsSortedByCreatedDate && (
          <li
            className="projects-showmore scroll scroll-right d-flex align-items-center">
            {
              <>
                {getPageNumber() < pageCount && (
                  <Button
                    className="btn-scroll"
                    onClick={() => scrollTo(state.scrollPercentage + scroll)
                    }
                    disabled={getPageNumber() === pageCount - 1}>
                    <ArrowForwardIosIcon
                      className={`projects-showmore-icon`}
                    />
                  </Button>
                )}
              </>
            }
          </li>
        )}

        <li className="projects-spacer"></li>
        <li className="projects-spacer"></li>
        <li className="projects-spacer"></li>
      </ul>
      {projectsSortedByCreatedDate.length > itemsPerPage ? (
        <ul className="pagination-dots d-flex justify-content-center">
          {paginationList}
        </ul>
      ) : (
        ''
      )}
    </ListFrame>
  )
}

export default ProjectCardList;