import React, { FC, useState } from 'react';
import { Modal } from 'Component/Modal';
import './ProjectDeleteModal.scss';
import { ProjectViewModel } from 'types/IDataContracts';
import { useDeleteProject } from 'hooks/projectHooks';

interface ProjectDeleteModalProps {
  project: ProjectViewModel;
  buttonContent: string | React.ReactElement;
}

const ProjectDeleteModal: FC<ProjectDeleteModalProps> = ({
  project,
  buttonContent,
}) => {
  const [showModal, setShowModal] = useState(false);
  const useDeleteProjectMutation = useDeleteProject();

  const handleDeleteProject = () => {
    useDeleteProjectMutation.mutate(project.id);
    setShowModal(() => false);
  };

  return (
    <Modal
      id="project-delete-modal"
      show={showModal}
      setShow={setShowModal}
      className={'modal-mw-993'}
      buttonContent={buttonContent}>
      <div className="text-center">
        <svg
          width="40"
          height="52"
          viewBox="0 0 40 52"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M3.00033 45.8333C3.00033 48.95 5.55033 51.5 8.66699 51.5H31.3337C34.4503 51.5 37.0003 48.95 37.0003 45.8333V11.8333H3.00033V45.8333ZM39.8337 3.33333H29.917L27.0837 0.5H12.917L10.0837 3.33333H0.166992V9H39.8337V3.33333Z"
            fill="#BEBEBE"
          />
        </svg>
        <div className="mt-4 text-large">
          Även beskrivningar som är kopplade till projektet kommer att raderas.
          <br />
          Vänligen läs <a href="https://byggtjanst.se/om-oss/allmanna-villkor" className="text-bottom-border pb-1" target="_blank" rel="noreferrer">användarvillkoren</a> för mer information.
          <br />
        </div>
        <button
          type="button"
          onClick={handleDeleteProject}
          className="btn btn-outline-primary del-btn mt-5">
          Ta bort
        </button>
      </div>
    </Modal>
  );
};

export default ProjectDeleteModal;
