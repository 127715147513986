import SpecificationCreateModal from 'Component/SpecificationCreateModal/SpecificationCreateModal';
import
{
  useTranslation,
} from 'hooks';
import { ProjectViewModel } from 'types/IDataContracts';
import React, { useState } from 'react';
import './SpecificationForm.scss';
import { Select } from 'Component/Select';

const SpecificationForm: React.FC<SpecificationFormProps> = ({ projectsWhereAdmin }) =>
{
  const [selectedProjectId, setSelectedProjectId] = useState<string>(projectsWhereAdmin[0].id);
  const t = useTranslation();

  const handleChange = (value: string) =>
  {
    setSelectedProjectId(() => value);
  };

  return (
    <>
      <h4 className="mb-4">{t('Välj projekt')}</h4>
      <div className="form-group">
        <Select
          offset={[0, 7]}
          onSelectedChanged={handleChange}
          options={projectsWhereAdmin.map((p) => ({
            text: p.name,
            value: p.id,
            selected: p.id === selectedProjectId
          }))} />
        <div data-testid="specificationform" className="specification-form-buttons my-5 d-flex justify-content-end">
          {
            <SpecificationCreateModal
              projectId={selectedProjectId}
              buttonContent={
                <button
                  className={'btn btn-outline-primary'}
                  disabled={projectsWhereAdmin.length === 0}
                  type="button">
                  {t('Nästa steg')}
                </button>
              }
            />
          }
        </div>
      </div>
    </>
  );
};

interface SpecificationFormProps
{
  projectsWhereAdmin: ProjectViewModel[]
}

export default SpecificationForm;
