import { useMutation, useQuery, useQueryClient } from 'react-query';
import { queryKeys, staleTime } from '../react-query/constants';
import useApi from './useApi';
import { CreateProjectCommand, ProjectViewModel } from 'types/IDataContracts';
import { ProblemDetails, UpdateProjectCommand } from 'types/IDataContracts';
import { Notification } from 'Component/Notifications';
import { useHistory } from 'react-router-dom';
import { useNotificationStore } from 'store';

const sortProjectsBydate = (data: ProjectViewModel[]) => data.sort((a, b) => +new Date(a.created) - +new Date(b.created));



const useProjects = () => {
  const api = useApi();

  const { data, isLoading } = useQuery([queryKeys.projects], async () => (await api.projectsList()).data, {
    staleTime: staleTime.thirtySeconds,
    select: sortProjectsBydate
  });

  const projects = data ?? [];
  return {
    projects,
    isLoading
  };
}

const useProject = (projectId: string) => {
  const api = useApi();
  const { data, isLoading } = useQuery([queryKeys.project, projectId], async () => await api.projectsDetail(projectId), {
    staleTime: staleTime.thirtySeconds,
  });
  const project = data?.data ?? {} as ProjectViewModel;
  return {
    project,
    isLoading
  }
}

const useProjectRoles = () => {
  const api = useApi();
  const { data } = useQuery([queryKeys.projectRoles], async () => (await api.projectParticipantsRolesList()).data, {
    staleTime: staleTime.infinity,
    cacheTime: staleTime.infinity,
  });

  const roles = data ?? []
  return {
    roles
  }
}

const useUpdateProject = () => {
  const api = useApi();
  const queryClient = useQueryClient();
  const { setNotification } = useNotificationStore();

  const mutation = useMutation<ProjectViewModel, ProblemDetails, UpdateProjectCommand>(
    async (command) => {
      const response = await api.update(command);
      return response.data;
    },
    {
      onSuccess: async (_, variables) => {
        const { id } = variables;
        await Promise.all([
          queryClient.invalidateQueries([queryKeys.project, id]),
          queryClient.invalidateQueries([queryKeys.projects])
        ]);
        setNotification(Notification.Success);
      },
    });

  return mutation;
}

const useCreateProject = () => {
  const api = useApi();
  const history = useHistory();
  const queryClient = useQueryClient();

  const mutation = useMutation<ProjectViewModel, ProblemDetails, CreateProjectCommand>(
    async (command) => {
      const response = await api.create(command);
      return response.data;
    },
    {
      onSuccess: async (data) => {
        await queryClient.invalidateQueries([queryKeys.projects]);
        history.push(`/projekt/${data.id}`);
      },
    });

  return mutation;
}

const useDeleteProject = () => {
  const api = useApi();
  const history = useHistory();
  const queryClient = useQueryClient();
  const { setNotification } = useNotificationStore();
  const mutation = useMutation<void, ProblemDetails, string>(
    async (projectId) => {
      const response = await api.delete(projectId);
      return response.data;
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([queryKeys.projects]);
        setNotification(Notification.Success);
        history.push('/');
      },
    });

  return mutation;
}



export {
  useProjects,
  useProject,
  useProjectRoles,
  useUpdateProject,
  useCreateProject,
  useDeleteProject,
};
