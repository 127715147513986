import React, { useContext, useEffect } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { AuthContext } from 'Authentication/Auth';
import { ApiClient } from 'api';
import { signinRedirectCallback } from 'Authentication/AuthUserManager';
import CircularProgress from '@material-ui/core/CircularProgress';
import LoginPage from 'Page/Login/LoginPage';

const SigninOidc: React.FC<RouteComponentProps> = () =>
{
  const history = useHistory();
  const authContext = useContext(AuthContext);

  useEffect(() =>
  {
    async function signinAsync()
    {
      const user = await signinRedirectCallback(window.location.href);
      if (user)
      {
        authContext.setApiClient(new ApiClient(user));
      }
      authContext.setUser(user);
      history.push(localStorage.getItem('redirectUri') || '/');
    }
    void signinAsync();
  }, []);

  return (
    <LoginPage>
      <h2 className="text-primary">
        <CircularProgress
          classes={{ colorPrimary: 'text-primary' }}
          color={'primary'}
          size={50}
        />
      </h2>
      <p>Du blir nu inloggad.</p>
      <p></p>
      <p>Vänligen vänta...</p>
    </LoginPage>
  );
};

export default SigninOidc;
