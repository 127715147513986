import React, { Suspense } from 'react';
import { AuthProtected } from 'Authentication/';
import NavBar from 'Component/Navigation/NavBar/NavBar';
import { TopBar } from 'Component/Navigation';
import Routes from 'Constants/Routes';
import { generateAppRoutes, IRoute } from '../Shared/Route/Routing';
import { Redirect, Route, Switch } from 'react-router-dom';
import Page from './Page';
import Skeleton from 'Component/Skeleton/Skeleton';
import { GoogleTagManager } from 'analytics/GoogleAnalytics';

const publicRoutes = generateAppRoutes(Routes.Public);
const privateRoutes = generateAppRoutes(Routes.Private);

function GetRoutes(routes: IRoute[], isPrivate: boolean) {
  return routes.map((route) => (
    <Route
      key={route.path}
      path={route.path}
      exact={route.exact}
      render={(props) => (
        <Page route={route} {...props} isPrivate={isPrivate} />
      )}
    />
  ));
}

const Main: React.FC = () => {
  return (
    <Switch>
      {GetRoutes(publicRoutes, false)}
      <AuthProtected>
        <GoogleTagManager />
        <div className="d-flex studio-content">
          <NavBar />
          <div className="d-flex flex-column studio-page">
            <Suspense fallback={<Skeleton />}>
              <TopBar />
              <Switch>{GetRoutes(privateRoutes, true)}</Switch>
            </Suspense>
          </div>
        </div>
        <Route render={() => <Redirect to="/" />} />
      </AuthProtected>
    </Switch>
  );
};

export default Main;
