import React from 'react';
import useProfile from 'hooks/useProfile';
import { useTranslation } from 'hooks';
import LatestSpecifications from 'Page/Home/LatestSpecifications';
import './HomePage.scss';
import ProjectCardList from './ProjectCardList';

const ListingSection = () => {
  return (
    <>
      <ProjectCardList />
      <LatestSpecifications />
    </>
  )
}

const HomePage = () => {
  const profile = useProfile();
  const t = useTranslation();

  return (
    <div className="mt-4">
      <h1 className="studio-page-title">Välkommen {profile?.given_name}</h1>
      <p className="mb-5">
        {t(
          'Välkommen till Byggtjänst Studio - detta är startsidan där du kan du se dina senaste beskrivningar och vilka projekt du är delaktig i. Klicka på "Skapa" om du vill skapa ett nytt projekt.'
        )}
      </p>
      <ListingSection />
    </div>
  );
};

export default HomePage;
