import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import ModalDialog from './ModalDialog';
import './Modal.scss';
import { ModalVisibility } from './constants';

const modalRoot = document.getElementById('modal-root');

const Modal: React.FC<ModalProps> = ({
  name,
  visibilityMap,
  showModalAction,
  buttonContent,
  killSwitch,
  ...otherProps
}) => {
  const [isInitiated, setIsInitiated] = useState(false);

  if (!modalRoot) {
    throw new Error('Modal component requires a modal-root element');
  }

  const toggleModalVisibilityHandler = (event?: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    event?.nativeEvent.stopPropagation();

    if (!isInitiated) {
      setIsInitiated(true);
    }

    if (isInitiated && Object.keys(visibilityMap).some(name => visibilityMap[name]) && killSwitch === true) {
      setIsInitiated(false);
      showModalAction('all', ModalVisibility.None);
      return Promise.resolve();
    }

    switch (visibilityMap[name]) {
      case ModalVisibility.None:
        showModalAction(name, ModalVisibility.Visible);
        break;
      case ModalVisibility.Hidden:
        showModalAction(name, ModalVisibility.Visible);
        break;
      case ModalVisibility.Visible:
        showModalAction(name, ModalVisibility.Hidden);
        break;
      default:
        setIsInitiated(false);
        showModalAction(name, ModalVisibility.None);
        break;
    }
    return Promise.resolve();
  };

  const isShown = (): boolean => {
    return isInitiated && (visibilityMap[name] === ModalVisibility.Visible || visibilityMap[name] === ModalVisibility.Hidden);
  };

  return (
    <>
      {React.cloneElement(buttonContent, {
        key: `modal-button-${name}`,
        onClick: toggleModalVisibilityHandler,
      })}

      {isShown() &&
        ReactDOM.createPortal(
          <ModalDialog
            name={name}
            visibilityMap={visibilityMap}
            showModalAction={showModalAction}
            {...otherProps}
          />,
          modalRoot,
          `modal-dialog-${name}`,
        )}
    </>
  );
};

interface ModalProps {
  name: string;
  visibilityMap: Record<string, ModalVisibility>;
  showModalAction: (name: string, value?: ModalVisibility) => void;
  buttonContent: React.ReactElement;
  header: React.ReactElement;
  footer?: React.ReactElement;
  internalClassModifier?: string;
  className?: string;
  killSwitch?: boolean;
}

export default Modal;
