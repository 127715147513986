import React from 'react';
import { AuthContext } from 'Authentication/Auth';

const useApi = () => {
  const { user, apiClient } = React.useContext(AuthContext);
  if (!apiClient) throw Error('Cannot connect to API');
  if (user && user !== apiClient.User) {
    apiClient.RefreshUser(user);
  }

  return {
    SendFile: apiClient.SendFile,
    ...apiClient.Api,
  };
}

export default useApi;
