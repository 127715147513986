/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import
{
  AddFeedbackCommand,
  AddParticipantToProjectCommand,
  AddParticipantToProjectCommandResult,
  ChangeParticipantRoleCommand,
  CreateProjectCommand,
  FunctionalSpecificationViewModel,
  ProblemDetails,
  ProjectRole,
  ProjectViewModel,
  RemoveParticipantFromProjectCommand,
  RoleDescriptionViewModel,
  SpecificationParticipantDto,
  UpdateProjectCommand,
  IProjectParticipantViewModel,
} from '../types/IDataContracts';
import { ContentType, HttpClient, RequestParams } from './HttpClient';

export class Api<
  SecurityDataType = unknown
> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Feedback
   * @name FeedbackCreate
   * @request POST:/api/Feedback
   * @secure
   */
  feedbackCreate = (data: AddFeedbackCommand, params: RequestParams = {}) =>
    this.request<void, ProblemDetails>({
      path: `/api/Feedback`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });

  /**
   * No description
   *
   * @tags Files
   * @name FilesDetail
   * @request GET:/api/Files/{fileName}
   * @secure
   */
  filesDetail = (fileName: string, params: RequestParams = {}) =>
    this.request<File, ProblemDetails>({
      path: `/api/Files/${fileName}`,
      method: 'GET',
      secure: true,
      format: 'blob',
      ...params,
    });
  /**
   * No description
   *
   * @tags Files
   * @name FilesCreate
   * @request POST:/api/Files
   * @secure
   */
  filesCreate = (
    data: {
      ContentType?: string;
      ContentDisposition?: string;
      Headers?: Record<string, string[]>;
      Length?: number;
      Name?: string;
      FileName?: string;
    },
    params: RequestParams = {}
  ) =>
    this.request<string, ProblemDetails>({
      path: `/api/Files`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags FunctionalSpecifications
   * @name GetFunctionalSpecificationsForUser
   * @summary Get all Functional Specifications connected to a user
   * @request GET:/api/FunctionalSpecifications/User
   * @secure
   */
  getFunctionalSpecificationsForUser = (params: RequestParams = {}) =>
    this.request<FunctionalSpecificationViewModel[], any>({
      path: `/api/FunctionalSpecifications/User`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags FunctionalSpecifications
   * @name GetParticipantsForSpecification
   * @summary Returns a collection of participants for a given specification
   * @request GET:/api/FunctionalSpecifications/{id}/Participants
   * @secure
   */
  getParticipantsForSpecification = (id: string, params: RequestParams = {}) =>
    this.request<SpecificationParticipantDto[], any>({
      path: `/api/FunctionalSpecifications/${id}/Participants`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags ProjectParticipants
   * @name ProjectParticipantsDetail
   * @summary Returns a collection of all participants for a given project
   * @request GET:/api/ProjectParticipants/{projectId}
   * @secure
   */
  projectParticipantsDetail = (projectId: string, params: RequestParams = {}) =>
    this.request<IProjectParticipantViewModel[], any>({
      path: `/api/ProjectParticipants/${projectId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags ProjectParticipants
   * @name AddParticipant
   * @summary Adds a participant to a project you are an admin in.
   * @request POST:/api/ProjectParticipants
   * @secure
   */
  addParticipant = (
    data: AddParticipantToProjectCommand,
    params: RequestParams = {}
  ) =>
    this.request<AddParticipantToProjectCommandResult, ProblemDetails>({
      path: `/api/ProjectParticipants`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags ProjectParticipants
   * @name UpdateRole
   * @summary Changes a role for a given participant.
   * @request PUT:/api/ProjectParticipants
   * @secure
   */
  updateRole = (
    data: ChangeParticipantRoleCommand,
    params: RequestParams = {}
  ) =>
    this.request<void, ProblemDetails>({
      path: `/api/ProjectParticipants`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ProjectParticipants
   * @name DeleteParticipant
   * @summary Removes a participant from a project
   * @request DELETE:/api/ProjectParticipants
   * @secure
   */
  deleteParticipant = (
    data: RemoveParticipantFromProjectCommand,
    params: RequestParams = {}
  ) =>
    this.request<void, ProblemDetails>({
      path: `/api/ProjectParticipants`,
      method: 'DELETE',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ProjectParticipants
   * @name ProjectParticipantsRolesList
   * @summary Returns a collection of available roles and their descriptions
   * @request GET:/api/ProjectParticipants/Roles
   * @secure
   */
  projectParticipantsRolesList = (params: RequestParams = {}) =>
    this.request<RoleDescriptionViewModel[], ProblemDetails>({
      path: `/api/ProjectParticipants/Roles`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Projects
   * @name ProjectsDetail
   * @summary Returns a project by id if the user has access
   * @request GET:/api/Projects/{projectId}
   * @secure
   */
  projectsDetail = (projectId: string, params: RequestParams = {}) =>
    this.request<ProjectViewModel, ProblemDetails>({
      path: `/api/Projects/${projectId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Projects
   * @name Delete
   * @summary Soft-deletes a project if the user has admin access.
   * @request DELETE:/api/Projects/{projectId}
   * @secure
   */
  delete = (projectId: string, params: RequestParams = {}) =>
    this.request<void, ProblemDetails>({
      path: `/api/Projects/${projectId}`,
      method: 'DELETE',
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Projects
   * @name ProjectsList
   * @summary Returns a collection of all projects the user is a participant in.
   * @request GET:/api/Projects
   * @secure
   */
  projectsList = (params: RequestParams = {}) =>
    this.request<ProjectViewModel[], ProblemDetails>({
      path: `/api/Projects`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Projects
   * @name Create
   * @summary Creates a new project and sets current user as administrator.
   * @request POST:/api/Projects
   * @secure
   */
  create = (data: CreateProjectCommand, params: RequestParams = {}) =>
    this.request<ProjectViewModel, ProblemDetails>({
      path: `/api/Projects`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Projects
   * @name Update
   * @summary Updates information about a project.
   * @request PUT:/api/Projects
   * @secure
   */
  update = (data: UpdateProjectCommand, params: RequestParams = {}) =>
    this.request<ProjectViewModel, ProblemDetails>({
      path: `/api/Projects`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  /**
   * No description
   *
   * @tags Licence
   * @name LicenceAmaFunktionStudioList
   * @summary Validates if the logged in user has a licence for Ama Funktion Studio
   * @request GET:/api/Licence/AmaFunktionStudio
   * @secure
   */
  licenceAmaFunktionStudioList = (params: RequestParams = {}) =>
    this.request<boolean, ProblemDetails | void>({
      path: `/api/Licence/AmaFunktionStudio`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
}
