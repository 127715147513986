import {
  InMemoryWebStorage,
  UserManagerSettings,
  WebStorageStateStore,
} from 'oidc-client';

export interface IAuthConfig {
  baseUrl: string;
  authority: string;
  audience: string;
  clientId: string;
}

export class AuthManagerConfig implements UserManagerSettings {
  constructor(config: IAuthConfig) {
    this.baseUrl = config.baseUrl;
    this.authority = config.authority;
    this.client_id = config.clientId;
    this.audience = config.audience;
    this.redirect_uri = this.baseUrl + '/signin-oidc';
    this.post_logout_redirect_uri = this.baseUrl + '/signout-oidc';
    this.silent_redirect_uri = this.baseUrl + '/silent-renew';
    this.userStore = new WebStorageStateStore({
      store: new InMemoryWebStorage(),
    });
  }

  public readonly baseUrl;
  public readonly authority: string;
  public readonly client_id: string;
  public readonly audience: string;
  public readonly redirect_uri: string;
  public readonly silent_redirect_uri: string;
  public readonly response_type = 'id_token token';
  public readonly scope =
    'openid profile userid sb-studio-platform sb-publicapi sb-ama-f roles';
  public readonly post_logout_redirect_uri: string;
  public readonly automaticSilentRenew = true;
  public readonly loadUserInfo = true;
  public readonly mergeClaims = true;
  public readonly userStore?: WebStorageStateStore;
}
