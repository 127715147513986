import { useAppConfig } from 'hooks';
import React from 'react';
import './CreateSpecificationIFrame.scss';

const CreateSpecificationIFrame: React.FC<CreateProps> = ({ projectId }) =>
{
  const { config } = useAppConfig();

  return projectId ? (
    <div className="iframe-container">
      <iframe
        title={projectId}
        src={`${config.AMASTUDIO_URL}/projects/${projectId}/create/new`}
        width="100%"
        height="100%"
        id="fs-frame"
        frameBorder="0"></iframe>
    </div>
  ) : null;
};

interface CreateProps
{
  projectId: string | undefined;
}

export default CreateSpecificationIFrame;
