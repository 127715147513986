import React, { FC } from 'react';
import ListFrame from 'Component/ListFrame/ListFrame';
import './index.scss';
import SpecificationListItem from 'Page/Specifications/SpecificationListItem';
import { useTranslation, useSpecifications } from 'hooks';

interface EmptyListProps
{
  bodyText: string;
}

const EmptyList: FC<EmptyListProps> = ({ bodyText }) =>
{
  return (
    <>
      <div className="mb-4 empty-items-content">
        <p>{bodyText}</p>
      </div>
      <div className="empty-items-box"></div>
    </>
  );
};

const SpecificationsPage = () =>
{
  const t = useTranslation();
  const { specifications } = useSpecifications();

  return (
    <>
      <h1 className="pt-2 mb-4">{t('Beskrivningar')}</h1>
      <div className="list-spacer">
        {specifications.length == 0 ? (
          <EmptyList
            bodyText="Här listas alla beskrivnigar som du skapat i ett projekt eller
            blivit inbjuden till av projektägaren. Tyvärr så finns inga
            beskrivningar som du är medlem i eller blivit inbjuden till än."
          />
        ) : (
          <p>
            Här listas dina egna beskrivningar eller beskrivningar som du är
            delaktig i.
          </p>
        )}
      </div>
      {specifications.length > 0 && (
        <ListFrame title={'Alla beskrivningar'}>
          <ul className="list-group">
            {specifications
              .sort((a, b) => +new Date(b.created) - +new Date(a.created))
              .map((s) => (
                <SpecificationListItem
                  key={s.id}
                  specification={s}
                />
              ))}
          </ul>
        </ListFrame>
      )}
    </>
  );
};

export default SpecificationsPage;
