import React from 'react';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

const ProjectCardEmptyIcon: React.FC<SvgIconProps> = (props) => {
  const color = props.color || '#E7E7E7';
  return (
    <svg width="131" height="131" viewBox="0 -25 120 131" fill={color} xmlns="http://www.w3.org/2000/svg">
        <g transform="scale(0.95)">
            <path d="M101.914 75.7498C101.703 76.442 101.673 77.1944 101.884 77.9769C102.368 79.8429 104.121 81.0768 106.056 81.0768H110.742V89.3533C110.742 89.5941 110.953 89.8048 111.195 89.8048H113.886C114.128 89.8048 114.339 89.5941 114.339 89.3533V81.0768H119.025C120.96 81.0768 122.713 79.8429 123.197 77.9769C123.409 77.1944 123.378 76.442 123.167 75.7498C124.255 74.9974 124.95 73.7634 124.95 72.3489C124.95 70.8741 124.164 69.58 122.985 68.8276C123.016 68.6771 123.016 68.5266 123.016 68.3461C123.016 66.1189 121.232 64.3131 119.025 64.1927C118.511 62.4472 116.939 61.1831 115.035 61.1831C114.097 61.1831 113.251 61.4841 112.556 61.9957C111.86 61.4841 111.014 61.1831 110.077 61.1831C108.172 61.1831 106.57 62.4472 106.086 64.1927C103.879 64.283 102.096 66.0888 102.096 68.3461C102.096 68.4965 102.096 68.647 102.126 68.8276C100.917 69.58 100.131 70.8741 100.131 72.3489C100.131 73.7634 100.826 74.9974 101.914 75.7498Z" fill={color} />
            <path d="M4.50432 75.7498C4.2927 76.442 4.26247 77.1944 4.47409 77.9769C4.95778 79.8429 6.71116 81.0768 8.64593 81.0768H13.3317V89.3533C13.3317 89.5941 13.5433 89.8048 13.7852 89.8048H16.4757C16.7175 89.8048 16.9292 89.5941 16.9292 89.3533V81.0768H21.6149C23.5497 81.0768 25.3031 79.8429 25.7868 77.9769C25.9984 77.1944 25.9682 76.442 25.7565 75.7498C26.8449 74.9974 27.5402 73.7634 27.5402 72.3489C27.5402 70.8741 26.7542 69.58 25.5752 68.8276C25.6054 68.6771 25.6054 68.5266 25.6054 68.3461C25.6054 66.1189 23.8218 64.3131 21.6149 64.1927C21.101 62.4472 19.529 61.1831 17.6245 61.1831C16.6873 61.1831 15.8409 61.4841 15.1455 61.9957C14.4502 61.4841 13.6038 61.1831 12.6666 61.1831C10.7621 61.1831 9.15986 62.4472 8.67616 64.1927C6.46932 64.283 4.6857 66.0888 4.6857 68.3461C4.6857 68.4965 4.6857 68.647 4.71593 68.8276C3.5067 69.58 2.7207 70.8741 2.7207 72.3489C2.7207 73.7634 3.41601 74.9974 4.50432 75.7498Z" fill={color} />
            <path d="M63.8193 37.7278H80.5873V11.5278H50.1953V17.8158H63.8193V37.7278Z" fill={color} />
            <path d="M30.2832 17.8159V85.9359H38.6672V73.3599H50.1952V17.8159H30.2832ZM39.7152 69.1679H34.4752V62.8799H39.7152V69.1679ZM39.7152 60.7839H34.4752V55.5439H39.7152V60.7839ZM39.7152 53.4479H34.4752V47.1599H39.7152V53.4479ZM39.7152 45.0639H34.4752V38.7759H39.7152V45.0639ZM39.7152 36.6799H34.4752V30.3919H39.7152V36.6799ZM39.7152 28.2959H34.4752V23.0559H39.7152V28.2959ZM49.1472 69.1679H43.9072V62.8799H49.1472V69.1679ZM49.1472 60.7839H43.9072V55.5439H49.1472V60.7839ZM49.1472 53.4479H43.9072V47.1599H49.1472V53.4479ZM49.1472 45.0639H43.9072V38.7759H49.1472V45.0639ZM49.1472 36.6799H43.9072V30.3919H49.1472V36.6799ZM43.9072 28.2959V23.0559H49.1472V28.2959H43.9072Z" fill={color} />
            <path d="M54.3873 85.9359H62.7713V37.7279H63.8193V17.8159H50.1953V73.3599H54.3873V85.9359ZM53.3393 23.0559H59.6273V28.2959H53.3393V23.0559ZM53.3393 30.3919H59.6273V36.6799H53.3393V30.3919ZM53.3393 38.7759H59.6273V45.0639H53.3393V38.7759ZM53.3393 47.1599H59.6273V53.4479H53.3393V47.1599ZM53.3393 55.5439H59.6273V60.7839H53.3393V55.5439ZM53.3393 69.1679V62.8799H59.6273V69.1679H53.3393Z" fill={color}/>
            <path d="M80.5879 37.728V44.016H83.7319V50.304H80.5879V53.448H83.7319V59.736H80.5879V61.832H83.7319V68.12H80.5879V71.264H83.4961H84.7799V85.936H97.3559V37.728H80.5879ZM94.2119 77.552H87.9239V71.264H94.2119V77.552ZM94.2119 68.12H87.9239V61.832H94.2119V68.12ZM94.2119 59.736H87.9239V53.448H94.2119V59.736ZM94.2119 50.304H87.9239V44.016H94.2119V50.304Z" fill={color}/>
            <path d="M77.4443 68.12V61.832H80.5883V59.736H77.4443V53.448H80.5883V50.304H77.4443V44.016H80.5883V37.728H63.8203V85.936H76.3963V71.264H77.4548H80.5883V68.12H77.4443ZM73.2523 77.552H66.9643V71.264H73.2523V77.552ZM73.2523 68.12H66.9643V61.832H73.2523V68.12ZM73.2523 59.736H66.9643V53.448H73.2523V59.736ZM73.2523 50.304H66.9643V44.016H73.2523V50.304Z" fill={color}/>
            <path d="M63.8195 37.728H62.7715V85.936H63.8195V37.728Z" fill={color} />
            <path d="M105.742 85.3218H21.9004V89.41H105.742V85.3218Z" fill={color} />
        </g>
    </svg>
  );
};

export default ProjectCardEmptyIcon;
