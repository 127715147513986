import React, { useRef } from 'react';
import
{
  Accordion,
  AccordionDetails,
  AccordionSummary,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import SpecificationAccordionItem from './SpecificationAccordionItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { shortDate } from 'util/FormatDate';
import { FunctionalSpecificationViewModel } from 'types/IDataContracts';
import { useUrlFormatter } from 'hooks';
import AMAStudioFIcon from 'lib/shared-assets/icons/AMAStudioF';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
    },
  })
);

const SpecificationAccordion: React.FC<SpecificationAccordionProps> = ({
  specification,
  isExpanded,
  onExpandedChange,
  className,
}) =>
{
  const classes = useStyles();
  const ref = useRef();
  const { amaFunktion } = useUrlFormatter();

  const handleChange = (panel: string) => (
    _event: React.ChangeEvent<unknown>,
    isExpanded: boolean
  ) =>
  {
    onExpandedChange(panel)(_event, isExpanded);
  };

  const link = amaFunktion(specification.id);

  return specification ? (
    <div className={classes.root}>
      <Accordion
        ref={ref}
        className={`specification-accordion ${className ?? ''}`}
        expanded={isExpanded(specification.id)}
        onChange={handleChange(specification.id)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${specification.id}bh-content`}
          id={`${specification.id}bh-header`}>
          <div className="specification-accordion__content">
            <a
              href={link}
              target="_bland"
              rel="norefferer"
              className="specification-accordion__body">
              <div className="specification-icon blue d-flex justify-content-center align-items-center me-4">
                <AMAStudioFIcon />
              </div>
              <div className="specification-accordion__details specification-accordion__details--main">
                <span className="specification-accordion__label specification-accordion__label--title specification-accordion__label--bold">
                  {specification.name}
                </span>
              </div>
              <div className="specification-accordion__details specification-accordion__details--extra align-items-end">
                <span className="specification-accordion__label specification-accordion__label--small specification-accordion__label--bold me-3">
                  {specification.statusName}
                </span>
                <span className="specification-accordion__label specification-accordion__label--small">
                  {shortDate(new Date(specification.created))}
                </span>
              </div>
            </a>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <SpecificationAccordionItem specification={specification} link={link} />
        </AccordionDetails>
      </Accordion>
    </div>
  ) : null;
};

interface SpecificationAccordionProps
{
  specification: FunctionalSpecificationViewModel
  isExpanded: (panelName: string) => boolean;
  onExpandedChange: (
    panelName: string
  ) => (event: React.ChangeEvent<unknown>, isExpanded: boolean) => void;
  className?: string;
}

export default SpecificationAccordion;
