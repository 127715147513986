import React, { useState } from 'react';
import UploadFiles from 'Shared/FileUpload/UploadFiles';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import Button from 'Component/Button/Button';
import ProjectLogo from './ProjectLogo';
import { ImageUploaderProps } from './ImageUploaderProps';
import './LogoUploader.scss';

const LogoUploader: React.FC<ImageUploaderProps> = ({
  title,
  editInline,
  containImage,
  isChanged,
  imageName,
  updateHandler,
  imageFileId,
  missingText,
  uploadHandler,
  errorHandler,
}) =>
{
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const deleteHandler = () =>
  {
    updateHandler(imageName, null);
    return Promise.resolve();
  };
  const onUploadedHandler = (fileId: string | undefined) =>
  {
    fileId && updateHandler(imageName, fileId);
    setIsEditing(false);
  };

  const image = (
    <ProjectLogo
      title={title}
      containImage={containImage}
      missingText={missingText}
      isChanged={isChanged}
      imageFileId={imageFileId}
      imageName={imageName}
    />
  );

  return (
    <div className="w-100 h-100 position-relative">
      <div
        className={
          'd-flex logo-edit-inline ' + (editInline ? ' logo-edit-inline' : '')
        }>
        <div className="d-flex ps-2 logo-edit-buttons">
          {imageFileId && !isEditing && (
            <Button
              className="btn btn-link button-edit"
              onClick={deleteHandler}>
              <DeleteIcon />
            </Button>
          )}
          {imageFileId && !isEditing && (
            <Button
              className="btn btn-link button-edit"
              onClick={() =>
              {
                setIsEditing(true);
                return Promise.resolve();
              }}>
              <EditIcon />
            </Button>
          )}
        </div>
      </div>
      <div className="form-logo-container d-flex justify-content-end">
        <UploadFiles
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          upload={uploadHandler}
          onUploadedHandler={onUploadedHandler}
          errorHandler={errorHandler}
        />
        {isEditing ? null : imageFileId ? (
          image
        ) : (
          <Button
            className="btn btn-logo button-edit"
            onClick={() =>
            {
              setIsEditing(true);
              return Promise.resolve();
            }}>
            {image}
          </Button>
        )}
      </div>
    </div>
  );
};

export default LogoUploader;
