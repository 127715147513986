/* eslint-disable */
import React from 'react';
import '@byggjanst/incidentfetcher/dist';
import { useAppConfig } from 'hooks';

const IncidentWidget = () => {
  const { config } = useAppConfig();
  return (
    <div>
      <sb-incident
        componentid={config.INCIDENTCOMPONENTID}
        development={config.ENVIRONMENT === 'Development'}></sb-incident>
    </div>
  );
};

export { IncidentWidget };
