import React from 'react';
import FeedbackModal, { FeedbackDto, FeedbackFormOptions, FeedbackModalOptions } from './FeedbackModal';
import './Feedback.scss';
type BaseType = React.ReactText | null | undefined;
type BaseFormType = Record<string, BaseType>;
type SupportedInputType = HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement;

const FeedbackWidget: React.FC<FeedbackWidgetProps> = ({
  modalOptions,
  formOptions,
  form,
  formRef,
}) => {
  return (
    <aside id="feedback">
      <FeedbackModal
        options={modalOptions}
        formOptions={formOptions}
        form={form}
        formRef={formRef}
      />
    </aside>
  );
};

export default FeedbackWidget;

export interface FeedbackWidgetProps {
  modalOptions: FeedbackModalOptions;
  formOptions: FeedbackFormOptions;
  form: ICustomForm<FeedbackDto>;
  formRef: React.MutableRefObject<HTMLFormElement | null>;
}

export interface ICustomForm<T = BaseFormType> {
  values: T;
  errors: Record<string, string>;
  touched: Record<string, boolean>;
  changed: Record<string, boolean>;
  onSubmitting: boolean;
  onBlur: boolean;
  updateValue: (propertyName: string, value: unknown) => void;
  handleChange: (
    event: React.ChangeEvent<SupportedInputType>
  ) => void;
  handleBlur: (
    event: React.ChangeEvent<SupportedInputType>
  ) => void;
  handleSubmit: (event?: React.FormEvent<HTMLFormElement>) => Promise<unknown>;
}