import React from 'react';
import Image from 'Component/Image/Image';
import { useTranslation } from 'hooks';
import Skeleton from 'Component/Skeleton/Skeleton';
import './ProjectInfoPanel.scss';
import { ProjectViewModel } from 'types/IDataContracts';

const ProjectInfoPanel: React.FC<ProjectInfoPanelProps> = (props) =>
{
  const { project } = props;
  const t = useTranslation();
  return (
    <div className="project-info-panel">
      <div className="project-image">
        {project?.projectImageFileId ? (
          <Image
            className="img-fluid"
            alt={`${project?.name} ${t('Projektbild')}`}
            src={project?.projectImageFileId}
          />
        ) : (
          <div className="d-flex justify-content-center align-items-center bg-secondary h-100 w-100 fst-italic">
            {t('Projektbild saknas')}
          </div>
        )}
      </div>
      <div className="project-info">
        <div className="d-flex w-100 h-50">
          <div className="h6 d-flex flex-grow-2 flex-shrink-1 flex-basis-1 shrinkable flex-column">
            <h4>{t('Projektnummer')}</h4>
            <p className=" pt-1">
              {project ? project.projectNumber : <Skeleton />}
            </p>

            <div className="h-50 pt-3 small">
              <h4>{t('Projektbeskrivning')}</h4>

              {project ? <p>{project.description}</p> : <Skeleton />}
            </div>
          </div>
          <div className="d-flex flex-grow-1 flex-shrink-1 flex-basis-1 shrinkable">
            {project?.logotypeFileId ? (
              <figure className="h6 project-logotype">
                <Image
                  className="py-2"
                  alt={`${project?.name} ${t('Logotyp')}`}
                  src={project?.logotypeFileId}
                />
              </figure>
            ) : (
              <div className="logotype-placeholder">
                {t('Logotyp till beskrivningar saknas')}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectInfoPanel;

interface ProjectInfoPanelProps
{
  project: ProjectViewModel;
}
