import React, { useEffect, useState } from 'react';
import { useTranslation } from 'hooks';
import { Modal } from 'Component/Modal';
import DescriptionIcon from '@material-ui/icons/Description';
import './TemplateInformationModal.scss';

const TemplateInformationModal: React.FC = () => {

  const t = useTranslation();

  const [showTemplateModal, setShowTemplateModal] = useState(false);

  const handleMessage = (evt: MessageEvent) => {
    if (evt.data === 'openTemplateInformationModal') {
      setShowTemplateModal(true)
    }
  };

  useEffect(() => {
    window.addEventListener('message', handleMessage);
    return () =>
      window.removeEventListener(
        'message',
        handleMessage
      );
  }, []);

  return (
    <Modal
      show={showTemplateModal}
      setShow={setShowTemplateModal}
      key="create-modal"
      name="fs-create"
      backdropClassName='backdrop-transparent'
      className={'modal--ama-fs-templates modal-mw-993'}
      header={
        <div className="specification-form-header d-flex flex-fill flex-column justify-content-center align-items-center ms-3 mt-5 pt-3 pb-3">
          <DescriptionIcon className="modal-icon gray-three" />
          <h4 className="modal-title text-primary mt-2">{t('Kravmall specifikation')}</h4>
        </div>
      }
      buttonContent={<></>}>
      <div className="d-flex justify-content-between px-5">
        <h5 className="fw-bold w-50">{t('En kravmall')}</h5>
        <div className="w-50">
          {t('Alla kravmallar innehåller/börjar med olika typer av byggnadverkskomplex. Den kravmall som väljs för att starta en funktionsbeskrivning används under hela processen, det går inte att ändra kravmall när en funktionsbeskrivning är påbörjad. Det går att lägga till egna objekt från CoClass och på så sätt anpassa strukturerna utifrån behov.')}
        </div>
      </div>
      <hr></hr>
      <div className="d-flex justify-content-between px-5">
        <h5 className="fw-bold w-50">{t('Tom kravmall')}</h5>
        <div className="w-50">
          {t('Används för att skapa en funktionsbeskrivning utan en förvald byggnadverkskomplex. Lägg till egna objekt från CoClass och för att skapa strukturer utifrån behov.')}
        </div>
      </div>
    </Modal>
  );
};


export { TemplateInformationModal };
