import { Notification } from 'Component/Notifications';
import { create } from 'zustand'

export interface NotificationStore {
    notification: Notification;
    setNotification: (notification: Notification) => void;
    removeNotification: () => void;
}

export const useNotificationStore = create<NotificationStore>((set) => ({
    notification: 0,
    setNotification: (notification: Notification) => set(() => ({ notification: notification })),
    removeNotification: () => set(() => ({ notification: Notification.Hidden }))
}));