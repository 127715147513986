import useProfile from "hooks/useProfile";
import React from 'react';
import Skeleton from 'Component/Skeleton/Skeleton';
import './Avatar.scss';

interface AvatarProps
{
  participant: IAvatarParticipant | undefined;
  inverted?: boolean;
  className?: string;
}

const Avatar: React.FC<AvatarProps> = ({
  participant,
  inverted,
  className,
}) =>
{
  const profile = useProfile();
  const invertedClass = inverted ? ' avatar-inverted' : '';

  const getBadgeClass = (userid: string | undefined): string =>
  {
    if (!participant || !participant.userId) return '';

    return userid === participant.userId ? 'lime' : '';
  }

  return participant ? (
    <span
      className={`avatar text-small d-flex justify-content-center align-items-center flex-grow-0 flex-shrink-0 ${className || ''
        } ${getBadgeClass(profile?.userid)} ${invertedClass}`}>
      {(participant?.firstName || 'N').charAt(0)}
      {(participant?.lastName || 'N').charAt(0)}
    </span>
  ) : (
    <Skeleton circle width="1.5em" height="1.5em" />
  );
};

export interface IAvatarParticipant
{
  firstName?: string | null;
  lastName?: string | null;
  userId?: string | null;
}

export default Avatar;
