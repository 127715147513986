import React from 'react';
import { useTranslation } from 'hooks';
import ParticipantAccordionList from './ParticipantAccordionList';
import { FunctionalSpecificationViewModel } from 'types/IDataContracts';

const SpecificationAccordionItem: React.FC<SpecificationAccordionItemProps> = ({
    specification, link
}) => {
    const t = useTranslation();

    const shouldTruncateText = (specification?.description?.length ?? 0) > 500;
    return (
        <div className="specification-accordion-item">
            <span className="specification-accordion-item__label--bold">
                {t('Funktionsbeskrivningsinfo')}
            </span>
            <p className="specification-accordion-item__text">
                {shouldTruncateText && specification.description ? `${specification.description.slice(0, 496)}...` : specification.description}
            </p>
            {shouldTruncateText && (
                <div className="specification-accordion-item__link">
                    <a
                        href={link}
                        target="_blank"
                        rel="noreferrer">
                        {t('Läs mer')}
                    </a>
                </div>
            )}
            <ParticipantAccordionList specification={specification} />
        </div>
    );
}

interface SpecificationAccordionItemProps {
    specification: FunctionalSpecificationViewModel;
    link: string;
}

export default SpecificationAccordionItem;