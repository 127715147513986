import React, { useEffect, useState } from 'react';
import useApi from 'hooks/useApi';
import Skeleton from 'Component/Skeleton/Skeleton';

const Image: React.FC<ImageProps> = ({ src, alt = '', className = '' }) =>
{
  const api = useApi();
  const [image, setImage] = useState<string | null>(null);
  const [imageSrc, setSrc] = useState<string | null>(null);

  useEffect(() =>
  {
    const fetchFile = async () =>
    {
      try
      {
        const res = await api.filesDetail(src);
        const img = URL.createObjectURL(res.data);
        setImage(img);
      } catch (err)
      {
        console.error(err);
      }
    };

    if (src !== imageSrc)
    {
      void fetchFile();
      setSrc(src);
    }
  }, [src]);

  return image !== null ? (
    <img className={className} alt={alt} src={image} />
  ) : (
    <Skeleton height="100%" width="100%" />
  );
};

interface ImageProps
{
  src: string;
  alt?: string;
  className?: string;
}

export default Image;
