import React, { useEffect, useRef } from 'react';
import './TextArea.scss'

const Textarea: React.FC<TextareaProps> = (props) => {
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const { label, className, ...otherProps } = props;
  const { autoFocus, value } = otherProps;
  const css = `form-control ${className || ''}`;

  useEffect(() => {
    if (autoFocus && textareaRef.current) {
      const length = value?.toString().length || 0;
      textareaRef.current.selectionStart = length;
      textareaRef.current.selectionEnd = length;
      textareaRef.current.focus();
    }
  }, [autoFocus, textareaRef])

  return (
    <>
      {label && <label className="form-label">{label}</label>}
      <textarea ref={textareaRef} className={css} {...otherProps} />
    </>
  );
};

interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
}

export default Textarea;
