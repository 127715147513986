import React from 'react';
import { shortDate } from 'util/FormatDate';
import './SpecificationCard.scss';
import { FunctionalSpecificationViewModel } from 'types/IDataContracts';
import { useProjects, useUrlFormatter } from 'hooks';
import AMAStudioFIcon from 'lib/shared-assets/icons/AMAStudioF';

const SpecificationCard: React.FC<SpecificationCardProps> = (props) =>
{
  const { specification } = props;
  const { projects } = useProjects();
  const { amaFunktion } = useUrlFormatter();
  const project = projects.find(p => p.id === specification.projectId);

  if (!project)
  {
    return <></>
  }

  const link = amaFunktion(specification.id);

  return (
    <li className="specification-item specification-card-spacing">
      <a
        href={link}
        target="_blank"
        rel="noreferrer"
        className="card specification-card">
        <div className="specification-shape">
          <div className="px-3 py-3 d-flex flex-row border-0 justify-content-between w-100 h-25">
            <div className="specification-icon blue d-flex justify-content-center align-items-center">
              <AMAStudioFIcon />
            </div>
          </div>
          <div className="card-body d-flex flex-column py-2">
            <p className="fw-bold text-truncated mb-0">{specification.name}</p>
            <p className="d-flex align-items-end mb-3">
              {specification && (
                <span className="specification-project-name text-truncated">
                  {`Projekt - ${project.name}`}
                </span>
              )}
            </p>
          </div>
          <div className="specification-card-divider mx-3" />
          <div className="card-footer bg-transparent d-flex justify-content-between align-items-center">
            {specification && (
              <span className="text-small fw-bold">
                {specification.statusName}
              </span>
            )}
            <span className="ps-1 studio-date">
              {specification && shortDate(new Date(specification.created))}
            </span>
          </div>
        </div>
      </a>
    </li>
  );
};

export default SpecificationCard;

interface SpecificationCardProps
{
  specification: FunctionalSpecificationViewModel;
}
