import React, { useContext, useEffect, useRef, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { IRoute } from 'Shared/Route/Routing';
import { FeedbackWidget } from '../Component/Feedback';
import { useCustomForm, useTranslation } from 'hooks';
import { AuthContext } from 'Authentication/Auth';
import { AddFeedbackCommand } from 'types/IDataContracts';
import { IncidentWidget } from 'Component/Incident';

const Page: React.FC<PageProps> = (props) => {
  const { route, ...otherProps } = props;
  const PageBody = route.component;
  const path = route.resolvedPath || route.path;
  const t = useTranslation();
  const { apiClient: api } = useContext(AuthContext);
  const formRef = useRef<HTMLFormElement | null>(null);
  const [val, setVal] = useState<AddFeedbackCommand>({
    description: 'Hej, ',
    url: props.route.path,
    title: props.route.description,
  });
  const customForm = useCustomForm({
    initialValues: val,
    onSubmitHandler: async (data) => {
      setVal({
        description: '',
        url: props.route.path,
        title: props.route.description,
      });
      return submitFeedback(data.values);
    },
    formRef: formRef,
  });

  useEffect(() => {
    document.title = getTitle(props);
  }, [path]);

  const submitFeedback = async (data: AddFeedbackCommand): Promise<boolean> => {
    try {
      const response = await api?.Api.feedbackCreate(data);
      return response?.ok ? true : false;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  return (
    <main role="main" className="page-container container mb-3">
      <IncidentWidget />
      <PageBody {...otherProps} />
      {props.isPrivate && (
        <FeedbackWidget
          modalOptions={{
            title: t('Byggtjänst Studio'),
            text: t(
              'Vi finns här för att hjälpa dig, kontakta kundservice eller lämna dina synpunkter. Vad kan vi göra för dig?'
            ),
            menuItems: [
              {
                title: t('Kontakta kundservice'),
                action: {
                  type: 'link',
                  url: window.appConfig.CUSTOMER_SERVICE_URL,
                  text: 'Kontakta oss',
                },
              },
              {
                title: t('Lämna dina synpunkter'),
                text: t('Hjälp oss att bli bättre.'),
                action: {
                  type: 'modal',
                  statusTexts: {
                    success: t('Skickat'),
                    error: t('Kunde inte skickas, '),
                  },
                  retryOptions: {
                    text: t('Försök igen'),
                    handler: submitFeedback,
                  },
                  text: t('Lämna synpunkter'),
                },
              },
            ],
          }}
          formOptions={{
            title: t('Byggtjänst Studio'),
            text: t(
              'Hjälp oss förbättra Byggtjänst Studio genom att lämna dina synpunkter'
            ),
          }}
          form={customForm}
          formRef={formRef}
        />
      )}
    </main>
  );
};

function getTitle(props: PageProps): string {
  const params = props.match.params;
  const title =
    props.route.nameResolver && params
      ? props.route.nameResolver(params)
      : props.route.name;

  return `${title} | Studio | Svensk Byggtjänst | Byggbranschens ledande kunskapsföretag`;
}

export default Page;

interface PageProps extends RouteComponentProps {
  route: IRoute;
  isPrivate: boolean;
}
