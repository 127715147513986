import React, { FC } from 'react';
import SpecificationAccordion from './SpecificationAccordion';
import { useSpecifications } from 'hooks';

const SpecificationAccordionList: FC<SpecificationAccordionListProps> = ({
    projectId
}) =>
{
    const [expandedMap, setExpandedMap] = React.useState<Record<string, boolean>>({});
    const { specifications } = useSpecifications();
    const filteredSpecifications = specifications.filter(s => s.projectId === projectId);

    const handleChange = (panel: string) => (_event: React.ChangeEvent<unknown>, isExpanded: boolean) =>
    {
        setExpandedMap((prevState) => ({ ...prevState, [panel]: isExpanded ? true : false }));
    };

    return (
        <>
            {filteredSpecifications.map((s) =>
                <SpecificationAccordion
                    key={s.id}
                    specification={s}
                    isExpanded={(panelName) => expandedMap[panelName] || false}
                    onExpandedChange={handleChange} />)
            }
        </>
    );
}

interface SpecificationAccordionListProps
{
    projectId: string
}

export default SpecificationAccordionList;