import { Modal } from 'Component/Modal';
import { useCustomForm, useProjectRoles, useTranslation, useUpdateParticipantRole } from 'hooks';
import React, { useRef, useState, FC } from 'react';
import {
  IProjectParticipantViewModel,
  ProjectRole,
} from 'types/IDataContracts';
import Button from 'Component/Button/Button';
import PeopleIcon from '@material-ui/icons/People';
import { Select } from 'Component/Select';
import './ParticipantEditModal.scss';

const ParticipantEditModal: FC<ParticipantEditModalProps> = (props) => {
  const { roles } = useProjectRoles();
  const useUpdateParticipantRoleMutation = useUpdateParticipantRole();
  const t = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);
  const formRef = useRef<HTMLFormElement | null>(null);
  const [val, setVal] = useState<IProjectParticipantViewModel>({
    ...props.participant,
  });

  const projectParticipantUpdate = async (data: IProjectParticipantViewModel) => {
    await useUpdateParticipantRoleMutation.mutateAsync({
      projectId: props.projectId,
      userId: data.userId,
      role: data.role,
    });

    setModalVisible(() => false);
  }

  const handleSelectedChanged = (value: string) => {
    setVal((prevState) => ({ ...prevState, role: value as ProjectRole }));
  };

  const { values, handleSubmit, onSubmitting } = useCustomForm({
    initialValues: val,
    onSubmitHandler: async (data) => {
      await projectParticipantUpdate(data.values);
    },
    formRef: formRef,
  });

  return (
    <Modal
      show={modalVisible}
      setShow={setModalVisible}
      name="participant-edit-modal"
      className="participant-edit-modal"
      buttonContent={
        props.buttonContent ? (
          props.buttonContent
        ) : (
          <button type="button" className="btn btn-link dropdown-item">
            {t('Redigera')}
          </button>
        )
      }
      header={
        <div className="d-flex flex-fill flex-column justify-content-center align-items-center participant-edit-modal__header">
          <PeopleIcon className="modal-icon gray-three" />
          <h4 className="modal-title text-primary">
            {t('Ändra befintlig behörighet')}
          </h4>
          <p className="text-center participant-edit-modal__text mt-2">
            {t('Ändra projektdeltagares åtkomst och ansvar i projektet.')}
          </p>
        </div>
      }>
      <form data-testid="editparticipantform" ref={formRef} onSubmit={handleSubmit}>
        <fieldset disabled={onSubmitting}>
          <div className="form-group">
            <h4 className="participant-edit-modal__label">{t('Roll')}</h4>
            <Select
              placement="bottom-start"
              offset={[0, 7]}
              options={roles.map((r) => ({
                text: r.roleName,
                description: r.description,
                value: r.role.toString(),
                selected: r.role === values.role,
              }))
              }
              onSelectedChanged={handleSelectedChanged}
            />
          </div>
          <div className="d-flex justify-content-end participant-edit-modal__footer">
            <Button type="submit" className="btn btn-outline-primary">
              {t('Uppdatera nu')}
            </Button>
          </div>
        </fieldset>
      </form>
    </Modal>
  );
};

interface ParticipantEditModalProps {
  projectId: string;
  participant: IProjectParticipantViewModel;
  buttonContent?: React.ReactElement;
}

export default ParticipantEditModal;
