import React, { useEffect, useState } from 'react';
import { usePopper } from 'react-popper';
import * as PopperJS from '@popperjs/core';
import PopoverDelay from './PopoverDelay';
import './Popover.scss';

function isClickOnPopperRefComponent(
  popper: HTMLElement | null,
  button: HTMLElement | null,
  ev: MouseEvent | TouchEvent
): boolean {
  return (
    (ev instanceof MouseEvent || ev instanceof TouchEvent) &&
    (ev.target === button || button?.contains(ev.target as Node) === true)
  );
}

function isMouseOnPopperRefComponent(ev: React.MouseEvent) {
  const clientRect = ev.currentTarget?.getBoundingClientRect();
  if (clientRect) {
    if (
      ev.nativeEvent.clientX < clientRect.x ||
      ev.nativeEvent.clientX > clientRect.right
    ) {
      return false;
    }
    if (
      ev.nativeEvent.clientY < clientRect.y ||
      ev.nativeEvent.clientY > clientRect.bottom
    ) {
      return false;
    }
  }

  return true;
}

function Options(props: PopoverProps): PopperJS.Options {
  return {
    modifiers: [
      { name: 'hide', options: { enabled: true } },
      { name: 'offset', options: { offset: props.offset ?? [] } },
    ],
    placement: props.placement || 'bottom',
    strategy: props.strategy || 'absolute',
  };
}

const Popover: React.FC<PopoverProps> = (props) => {
  const { content, children, bubbleRequired } = props;
  const [showPopper, setShowPopper] = useState(false);
  const [popper, setPopperRef] = useState<HTMLDivElement | null>(null);
  const [popover, setPopoverRef] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(popper, popover, Options(props));

  const onMouseEnterHandler: React.EventHandler<React.SyntheticEvent> = (
    ev: React.MouseEvent
  ) => {
    if (!isMouseOnPopperRefComponent(ev)) {
      return;
    }
    if (!showPopper) {
      setShowPopper(true);
    }
  };

  const onMouseLeaveHandler: React.EventHandler<React.SyntheticEvent> = () => {
    if (showPopper) {
      setShowPopper(false);
    }
  };

  const onHideHandler = (ev: MouseEvent | KeyboardEvent) => {
    if (ev instanceof KeyboardEvent) {
      return ev.key === 'Escape' ? setShowPopper(false) : undefined;
    }

    if (!isClickOnPopperRefComponent(popper, popover, ev)) {
      setShowPopper(false);
    }
  };

  function unBindEvents() {
    window.removeEventListener('click', onHideHandler);
    window.removeEventListener('keydown', onHideHandler);
  }

  useEffect(() => {
    if (showPopper) {
      window.addEventListener('click', onHideHandler);
      window.addEventListener('keydown', onHideHandler);
    } else {
      unBindEvents();
    }

    return unBindEvents;
  });

  const openClass = showPopper ? ' open' : '';

  return (
    <>
      <div
        className={`popover-ref d-flex`}
        ref={setPopperRef}
        onMouseEnter={onMouseEnterHandler}
        onMouseLeave={onMouseLeaveHandler}>
        {children}
      </div>
      <PopoverDelay show={showPopper} delay={props.delay || 0}>
        <div
          className={`popover ${openClass} ${bubbleRequired ? 'bubble text-center' : ''}`}
          ref={setPopoverRef}
          style={styles.popper}
          {...attributes.popper}>
            {content}
        </div>
      </PopoverDelay>
    </>
  );
};

interface PopoverProps {
  content: React.ReactElement;
  className?: string;
  placement?: PopperJS.Placement;
  strategy?: PopperJS.PositioningStrategy;
  delay?: number;
  offset?: number[];
  bubbleRequired?: boolean;
}

export default Popover;
