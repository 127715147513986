import React from 'react';
import { Modal } from 'Component/Modal';
import FormHeader from './FormHeader';
import { useApi, useCreateProject } from 'hooks';
import ProjectForm from 'Component/ProjectForm/ProjectForm';
import { ProjectViewModel } from 'types/IDataContracts';

const CreateProjectModal = ({
  modalTriggerButton,
}: CreateProjectModalProps): JSX.Element => {
  const [showModal, setShowModal] = React.useState(false);
  const api = useApi();
  const useCreateProjectMutation = useCreateProject();

  const handleSubmit = async (data: ProjectViewModel) => {
    await useCreateProjectMutation.mutateAsync(data);
    setShowModal(() => false);
  };

  return (
    <Modal
      className="modal-mw-993"
      show={showModal}
      setShow={setShowModal}
      key="CreateForm"
      header={<FormHeader title={'Skapa projekt'} />}
      buttonContent={modalTriggerButton}>
      <ProjectForm
        onSubmitHandler={handleSubmit}
        onFileUploadHandler={api.SendFile}
        submitButtonText={'Skapa'} />
    </Modal>
  );
};

interface CreateProjectModalProps {
  modalTriggerButton: React.ReactElement;
}

export default CreateProjectModal;
