import React, { useEffect } from 'react';
import { useTranslation, useUser } from 'hooks';
import ListFrame from 'Component/ListFrame/ListFrame';
import ProjectAddParticipantByEmailModal from 'Component/ProjectAddParticipantModal/ProjectAddParticipantByEmailModal';
import EditIcon from '@material-ui/icons/Edit';
import ParticipantDeleteModal from 'Component/ProjectParticipantDeleteModal/ParticipantDeleteModal';
import { IProjectParticipantViewModel } from 'types/IDataContracts';
import Skeleton from 'Component/Skeleton/Skeleton';
import ParticipantAccordion from './ParticipantAccordion';
import ParticipantEditModal from 'Component/ProjectEditParticipantRoleModal/ParticipantEditModal';

const ParticipantAccordionList: React.FC<ParticipantAccordionListProps> = ({
  projectId,
  participants: projectParticipants
}) =>
{
  const t = useTranslation();
  const [expandedMap, setExpandedMap] = React.useState<Record<string, boolean>>(
    {}
  );
  const profile = useUser();

  const participants = (projectParticipants || []).sort((a, b) =>
  {
    return (
      a.role.localeCompare(b.role) ||
      a.lastName.localeCompare(b.lastName) ||
      a.firstName.localeCompare(b.firstName)
    );
  });

  useEffect(() =>
  {
    if (participants.length > 0)
    {
      setExpandedMap((prevState) => ({
        ...prevState,
        ...participants
          .map((p) => ({ [p.userId.toString()]: false }))
          .reduce((p, c) => ({ ...p, ...c })),
      }));
    }
  }, [participants]);

  const userIsAdmin =
    profile &&
    participants.find((p) => p.userId.toString() === profile.userid)?.role ===
    'Admin';

  const participantIsLoggedInUser = (p: IProjectParticipantViewModel) =>
  {
    return profile && parseInt(profile.userid) === p.userId;
  };

  const handleChange = (panel: string) => (
    _event: React.ChangeEvent<unknown>,
    isExpanded: boolean
  ) =>
  {
    setExpandedMap({ ...expandedMap, [panel]: isExpanded ? true : false });
  };

  return (
    <ListFrame
      title={t('Projektmedlemmar')}
      rightButtons={
        userIsAdmin
          ? [
            <ProjectAddParticipantByEmailModal
              key="addMembers"
              projectId={projectId}
            />,
          ]
          : undefined
      }>
      {!participants ? <Skeleton /> : participants.map((p) => (
        <ParticipantAccordion
          key={p.userId}
          participant={{
            ...p,
          }}
          isExpanded={(panel) => expandedMap[panel] || false}
          onExpandedChange={handleChange}>
          <div className="d-flex align-items-start justify-content-between flex-fill participant-accordion-item">
            <div className="d-flex flex-column flex-fill participant-accordion-item__content">
              <div className="d-flex flex-column participant-accordion-item__details">
                <div className="d-flex justify-content-between">
                  <span className="participant-accordion-item__label--bold">
                    {t('Epost *')}
                  </span>
                  {participantIsLoggedInUser(p) && (
                    <div className="d-flex">
                      <a
                        href={window.appConfig.BYGGTJANST_SETTINGS}
                        target="_blank"
                        rel="noreferrer"
                        className="btn btn-link participant-accordion-item__link">
                        <EditIcon />
                      </a>
                    </div>
                  )}
                </div>
                <span className="participant-accordion-item__label--muted">
                  {p.email}
                </span>
              </div>
              <div className="d-flex flex-column participant-accordion-item__details">
                <div className="d-flex justify-content-between">
                  <span className="participant-accordion-item__label--bold">
                    {t('Roll')}
                  </span>
                  {userIsAdmin && !participantIsLoggedInUser(p) && (
                    <ParticipantEditModal
                      key="edit-modal"
                      participant={p}
                      projectId={projectId}
                      buttonContent={
                        <button
                          type="button"
                          className="btn btn-link participant-accordion-item__button">
                          <EditIcon />
                        </button>
                      }
                    />
                  )}
                </div>
                <span className="participant-accordion-item__label--muted">
                  {p.roleName}
                </span>
                {userIsAdmin && !participantIsLoggedInUser(p) && (
                  <>
                    <hr />
                    <div className="d-flex justify-content-between">
                      <span className="participant-accordion-item__label--bold mt-3">
                        {t('Ta bort deltagare')}
                      </span>
                      <ParticipantDeleteModal
                        participant={p}
                        projectId={projectId}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </ParticipantAccordion>
      ))}
    </ListFrame>
  );
};

interface ParticipantAccordionListProps
{
  projectId: string;
  participants: IProjectParticipantViewModel[];
}

export default ParticipantAccordionList;
