import React from 'react';
import { useParams } from 'react-router-dom';
import { useProjectParticipants, useTranslation, useUser } from 'hooks';
import EditIcon from '@material-ui/icons/EditOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import ProjectInfoPanel from 'Page/Project/ProjectInfoPanel';
import EditProjectModal from 'Component/ProjectForm/EditProjectModal';
import './index.scss';
import { useProject } from 'hooks';
import Skeleton from 'Component/Skeleton/Skeleton';
import ParticipantAccordionList from 'Component/ProjectParticipantList/ParticipantAccordionList';
import ProjectDeleteModal from 'Component/ProjectDeleteModal/ProjectDeleteModal';
import ProjectSpecificationList from './ProjectSpecificationList';

type ViewParams = {
  id: string
}

const View = () =>
{
  const t = useTranslation();
  const profile = useUser();
  const { id: projectId } = useParams<ViewParams>();
  const { participants } = useProjectParticipants(projectId);
  const { project, isLoading } = useProject(projectId);

  if (isLoading)
  {
    return <Skeleton />
  }

  const currentParticipant = participants.find((p) => p.userId.toString() === profile?.userid);
  const isAdmin = currentParticipant?.role === 'Admin';

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between mb-1">
        <div className="pt-1 d-flex align-items-center">
          <h1>{project.name}</h1>
          {isAdmin && (
            <EditProjectModal
              project={project}
              modalTriggerButton={
                <button className="btn btn-link">
                  <EditIcon />
                </button>
              }
            />
          )}
        </div>

        {isAdmin && (
          <ProjectDeleteModal
            project={project}
            buttonContent={
              <button
                type="button"
                className="btn btn-link d-flex justify-content-center del-project-btn">
                <DeleteIcon className="mr-4" /> <span>Ta bort projekt</span>
              </button>
            }></ProjectDeleteModal>
        )}
      </div>

      <ProjectInfoPanel project={project} />
      <div className="row">
        <div className="col-lg-6">
          <div className="panel">
            {
              <ProjectSpecificationList
                project={project}
                userRole={currentParticipant?.role}
                title={t('Mina beskrivningar')}
                subTitle={t('Beskrivningar där du är deltagare')}
              />
            }
          </div>
        </div>
        <div className="col-lg-6 panel">
          {<ParticipantAccordionList
            projectId={projectId}
            participants={participants}
          />
          }
        </div>
      </div>
    </div>
  );
};

export default View;
