import React from 'react';
import { useTranslation } from 'hooks';
import Image from 'Component/Image/Image';
import AttachFileIcon from '@material-ui/icons/AttachFile';

const ProjectLogo: React.FC<ProjectLogoProps> = (props) =>
{
  const t = useTranslation();
  const { imageFileId, imageName, containImage, isChanged, title, missingText = t('Bild saknas') } = props;
  return (
    <div className={'d-flex w-100 h-100' + (containImage ? ' contain-image' : '')}>
      {imageFileId ? (
        <Image
          className={'' + isChanged(imageName)}
          alt={t(title)}
          src={imageFileId}
        />
      ) : (
        <div
          className={
            'd-flex flex-column justify-content-center placeholder-logo' + isChanged(imageName)
          }>
          <AttachFileIcon />
          {missingText}
        </div>
      )}
    </div>
  );
};

interface ProjectLogoProps
{
  title: string;
  missingText?: string;
  containImage?: boolean;
  imageName: string;
  isChanged: (n: string) => string;
  imageFileId?: string | null;
}

export default ProjectLogo;
