import React from 'react';
import { NavBarItemProps } from './NavBarItemProps';

const NavLink: React.FC<NavBarItemProps> = (props) => {
  const { item, onClick } = props;
  const IconComponent = item.icon;
  return (
    <li className="interactive navbar-item">
      <a onClick={onClick} href={item.url} className="d-flex item-gray align-items-center">
        {IconComponent && <IconComponent className="navbar-icon" />}
        <div className="expanded-text d-flex flex-column">
          <span>{item.text}</span>
          <small className="text-gray">{item.subText}</small>
        </div>
      </a>
    </li>
  );
};

export default NavLink;
