import
  {
    HomePage,
    SpecificationsPage,
  } from 'Page/';
import { IRoute } from '../Shared/Route/Routing';
import Projects from 'Page/Projects';
import Project from 'Page/Project';
import
  {
    SignoutOidc,
    SigninOidc,
    Logout,
    SilentRenew,
  } from 'Authentication';

interface IRoutes
{
  Public: IRoute[];
  Private: IRoute[];
}

const routes: IRoutes = {
  Public: [
    {
      path: '/signout-oidc',
      name: 'Logga ut',
      description: 'Uloggningssida',
      component: SignoutOidc,
      exact: true,
    },
    {
      path: '/signin-oidc',
      name: 'Logga in',
      description: 'Logga in',
      component: SigninOidc,
      exact: true,
    },
    {
      path: '/silent-renew',
      name: 'Logga in',
      description: 'Logga in',
      component: SilentRenew,
      exact: true,
    },
    {
      path: '/utloggad',
      name: 'Utloggad',
      description: 'Du är nu utloggad',
      component: Logout,
      exact: true,
    },
  ],
  Private: [
    {
      path: '/',
      name: 'Hem',
      description: 'Startsida',
      component: HomePage,
      exact: true,
      routes: [
        {
          path: '/beskrivningar',
          name: 'Beskrivningar',
          description: 'Lista beskrivningar',
          component: SpecificationsPage,
        },
        {
          path: '/projekt',
          component: Projects,
          exact: true,
          name: 'Projekt',
          description: 'Lista projekt',
          routes: [
            {
              path: '/:id',
              name: 'Projekt',
              description: 'Visa projekt',
              component: Project,
              exact: true,
              routes: [],
            },
          ],
        },
      ],
    },
  ],
};

export default routes;
