import React, {
  useEffect,
  useState,
  useRef,
  SetStateAction,
  Dispatch,
} from 'react';
import { makeCancelable, IPromiseCancellable } from 'Shared/FileUpload/Promise';
import UploadProgress from './UploadProgress';
import './UploadFiles.scss';
import { ValidationError } from 'types/IDataContracts';

const UploadFiles: React.FC<FileUploaderProps> = (props) =>
{
  const [progress, setProgress] = useState<number>(-1);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const onUploadedHandlerPromise = useRef<IPromiseCancellable<unknown>>();

  const selectFile = () =>
  {
    const input = fileInputRef.current;
    if (input && input.files && input.files.length)
    {
      void upload(input.files[0]);
    }
  };

  const upload = (currFile: File) =>
  {
    const onProgressHandler = (event: ProgressEvent<EventTarget>) =>
    {
      return setProgress(Math.round((100 * event.loaded) / event.total));
    };
    setProgress(0);
    props.setIsEditing(true);
    onUploadedHandlerPromise.current = makeCancelable(
      props
        .upload(currFile, onProgressHandler)
        .then((r) =>
        {
          if (props.onUploadedHandler)
          {
            props.onUploadedHandler(r, currFile);
            props.errorHandler('');
          }
        })
        .catch((err: ValidationError) =>
        {
          let errorMsg = '';

          if (
            err.status === 413 ||
            err.errors[0].propertyName === 'File.Length'
          )
          {
            errorMsg = 'Bilden får inte överstiga 3Mb';
          } else if (
            err.errors[0].propertyName === 'File.ContentType' ||
            err.errors[0].propertyName === 'File'
          )
          {
            errorMsg = 'Möjliga filformat är jpeg, jpg, png, ief, gif, bmp';
          }

          props.errorHandler(errorMsg);
        })
        .finally(() =>
        {
          props.setIsEditing(false);
          setProgress(-1);
        })
    );
    return onUploadedHandlerPromise.current.promise;
  };

  useEffect(() =>
  {
    return () =>
    {
      if (onUploadedHandlerPromise.current)
      {
        onUploadedHandlerPromise.current.cancel();
      }
    };
  }, []);

  useEffect(() =>
  {
    if (props.isEditing)
    {
      if (progress < 0)
      {
        if (fileInputRef.current !== null)
        {
          fileInputRef.current.click();
        }
        props.setIsEditing(false);
      }
    }
  }, [props.isEditing]);

  return (
    <>
      {props.isEditing && <UploadProgress progress={progress} />}
      <input
        className="d-none"
        ref={fileInputRef}
        type="file"
        accept="image/*"
        onChange={selectFile}
      />
    </>
  );
};

interface FileUploaderProps
{
  isEditing: boolean;
  setIsEditing: (v: boolean) => void;
  upload: (
    file: File,
    onProgressHandler: (event: ProgressEvent<EventTarget>) => void
  ) => Promise<string>;
  onUploadedHandler?: (fileId: string | undefined, file?: File) => void;
  errorHandler: Dispatch<SetStateAction<string>>;
}

export default UploadFiles;
