import React, { useRef } from 'react';
import Accordion from '@material-ui/core/Accordion';
import { makeStyles, createStyles, AccordionDetails, AccordionSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ParticipantAvatar from 'Component/Avatar/Avatar';
import './ParticipantAccordion.scss';
import { IProjectParticipantViewModel } from 'types/IDataContracts';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
    },
  }),
);

interface ParticipantAccordionProps
{
  participant: IProjectParticipantViewModel;
  isExpanded: (panel: string) => boolean;
  onExpandedChange: (panelName: string) => (event: React.ChangeEvent<unknown>, isExpanded: boolean) => void;
  className?: string;
}


const ParticipantAccordion: React.FC<ParticipantAccordionProps> = ({
  participant,
  isExpanded,
  onExpandedChange,
  children,
}) =>
{
  const classes = useStyles();
  const ref = useRef();

  const handleChange = (panel: string) => (_event: React.ChangeEvent<unknown>, isExpanded: boolean) =>
  {
    onExpandedChange(panel)(_event, isExpanded);
  };

  return (
    <div className={classes.root}>
      <Accordion ref={ref} className="participant-accordion" expanded={isExpanded(`${participant.userId}`)} onChange={handleChange(`${participant.userId}`)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${participant.userId}bh-content`}
          id={`${participant.userId}bh-header`}
        >
          <div className="participant-accordion__content">
            <ParticipantAvatar
              participant={{ ...participant, userId: participant.userId.toString() }}
              inverted={true}
              className={`me-0 me-sm-2 s-36-px`}
            />
            <div className="flex-grow-1 px-4 flex-basis-3 participant-accordion__label participant-accordion__label--bold">
              {participant.firstName} {participant.lastName}
            </div>
            <div className="flex-grow-1 px-2 flex-basis-2 participant-accordion__label participant-accordion__label--muted">
              {participant.roleName}
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          {children}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}


export default ParticipantAccordion;