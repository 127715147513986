import React, { useState } from 'react';
import { useTimeout } from '../../hooks';

const PopoverDelay: React.FC<PopoverDelayProps> = ({ show, delay, children }) =>
{
    const [showPopover, setShowPopover] = useState<boolean>(false);
    useTimeout(() =>
    {
        setShowPopover(show);
    }, show ? delay : 0, [show]);

    return showPopover ? <>{children}</> : <></>;
}

interface PopoverDelayProps
{
    show: boolean;
    delay: number;
}

export default PopoverDelay;