import React from 'react';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

const RoleSpecificationIcon: React.FC<SvgIconProps> = () => {
  return (
    <svg width="68" height="34" viewBox="0 0 68 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M34 19.125C38.6183 19.125 42.6983 20.23 46.0133 21.675C49.0733 23.035 51 26.095 51 29.41V34H17V29.4383C17 26.095 18.9267 23.035 21.9867 21.7033C25.3017 20.23 29.3817 19.125 34 19.125ZM11.3333 19.8333C14.45 19.8333 17 17.2833 17 14.1667C17 11.05 14.45 8.5 11.3333 8.5C8.21667 8.5 5.66667 11.05 5.66667 14.1667C5.66667 17.2833 8.21667 19.8333 11.3333 19.8333ZM14.535 22.95C13.4867 22.78 12.4383 22.6667 11.3333 22.6667C8.52833 22.6667 5.865 23.2617 3.45667 24.31C1.36 25.2167 0 27.2567 0 29.5517V34H12.75V29.4383C12.75 27.0867 13.4017 24.8767 14.535 22.95ZM56.6667 19.8333C59.7833 19.8333 62.3333 17.2833 62.3333 14.1667C62.3333 11.05 59.7833 8.5 56.6667 8.5C53.55 8.5 51 11.05 51 14.1667C51 17.2833 53.55 19.8333 56.6667 19.8333ZM68 29.5517C68 27.2567 66.64 25.2167 64.5433 24.31C62.135 23.2617 59.4717 22.6667 56.6667 22.6667C55.5617 22.6667 54.5133 22.78 53.465 22.95C54.5983 24.8767 55.25 27.0867 55.25 29.4383V34H68V29.5517ZM34 0C38.7033 0 42.5 3.79667 42.5 8.5C42.5 13.2033 38.7033 17 34 17C29.2967 17 25.5 13.2033 25.5 8.5C25.5 3.79667 29.2967 0 34 0Z" fill="#BEBEBE"/>
    </svg>

  );
};

export default RoleSpecificationIcon;
