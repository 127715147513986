import React from 'react';

function AMAStudioF(): React.ReactElement {
  return (
    <div className="specification-icon blue d-flex justify-content-center align-items-center font-weight-700">
      <span className="text-small">FS</span>
    </div>
  );
}

export default AMAStudioF;
