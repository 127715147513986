import React, { FC } from 'react';
import { useTranslation } from 'hooks';
import
{
  Accordion,
  AccordionDetails,
  AccordionSummary,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ParticipantAvatar from 'Component/Avatar/Avatar';
import { FunctionalSpecificationViewModel } from 'types/IDataContracts';
import { useSpecificationParticipants } from 'hooks';
import Skeleton from 'Component/Skeleton/Skeleton';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
    },
  })
);

interface ParticipantAccordionListProps
{
  specification: FunctionalSpecificationViewModel;
}


const ParticipantAccordionList: React.FC<ParticipantAccordionListProps> = ({
  specification,
}) =>
{
  const classes = useStyles();
  const t = useTranslation();
  const [expandedMap, setExpandedMap] = React.useState<Record<string, boolean>>(
    {
      [specification.id]: false,
    }
  );

  const handleChange = (panel: string) => (
    _event: React.ChangeEvent<unknown>,
    isExpanded: boolean
  ) =>
  {
    setExpandedMap((prevState) => ({ ...prevState, [panel]: isExpanded ? true : false }));
  };

  return (
    <div className={classes.root}>
      <Accordion
        className={`specification-accordion-participants`}
        expanded={expandedMap[specification.id] === true}
        onChange={handleChange(specification.id)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${specification.id}bh-content`}
          id={`${specification.id}bh-header`}>
          <span className="specification-accordion-participants__label--bold">
            {t('Visa funktionsbeskrivnings deltagare')}
          </span>
        </AccordionSummary>
        {expandedMap[specification.id] === true &&
          <SpecificationParticipantDetails specificationId={specification.id} />
        }
      </Accordion>
    </div>
  );
};


interface SpecificationParticipantDetailsProps
{
  specificationId: string
}

const SpecificationParticipantDetails: FC<SpecificationParticipantDetailsProps> = ({ specificationId }) =>
{
  const { participants, isLoading } = useSpecificationParticipants(specificationId);

  const renderParticipants = () =>
  {
    return participants.map((participant) =>
    {
      return (
        <div
          className="specification-accordion-participants__item"
          key={participant.userId}>
          <ParticipantAvatar
            participant={{
              ...participant,
              userId: participant.userId.toString(),
            }}
            inverted={true}
            className={`me-0 me-sm-2 s-36-px`}
          />
          <div className="specification-accordion-participants__info">
            <div className="">
              <span className="specification-accordion-participants__label specification-accordion-participants__label--bold">
                {participant.firstName} {participant.lastName}
              </span>
            </div>
            <div className="specification-accordion-participants__info-text specification-accordion-participants__info-text--grow">
              <span className="specification-accordion-participants__label">
                {participant.friendlyRoleName}
              </span>
            </div>
          </div>
        </div>
      );
    })
  }

  return (
    <AccordionDetails>
      <div className="specification-accordion-participants__details">
        {isLoading ? <Skeleton /> : renderParticipants()}
      </div>
    </AccordionDetails>
  )
}


export default ParticipantAccordionList;
