import { useQuery } from 'react-query';
import { queryKeys, staleTime } from 'react-query/constants';
import useApi from './useApi';

const useSpecificationParticipants = (specificationId: string) => {
  const api = useApi();
  const { data, isLoading } = useQuery([queryKeys.specificatonParticipants, specificationId],
    async () => {
      const res = await api.getParticipantsForSpecification(specificationId);
      return res.data;
    }, {
    staleTime: staleTime.oneMinute,
    select: (data) => {
      return data.sort((a, b) => a.friendlyRoleName.localeCompare(b.friendlyRoleName) ||
        a.lastName.localeCompare(b.lastName) ||
        a.firstName.localeCompare(b.firstName)
      );
    }
  });

  const participants = data ?? [];
  return {
    participants,
    isLoading
  }
}

export { useSpecificationParticipants };