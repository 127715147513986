import React from 'react';
import { Link } from 'react-router-dom';
import { NavBarItemProps } from './NavBarItemProps';

const NavPath: React.FC<NavBarItemProps> = (props) =>
{
  const { item, onClick } = props;
  const IconComponent = item.icon;

  const className = `interactive ${item.path &&
    (item.path === props.currentPath || (!item.exactPath && props.currentPath === item.path))
    ? 'active'
    : ''
    }`;
  return (
    <li className={className + ' navbar-item'}>
      <Link onClick={onClick} to={item.path || ''} className="d-flex align-items-center">
        {IconComponent && <IconComponent className="navbar-icon px-0 py-0" />}
        <div className="expanded-text d-flex flex-column">
          <span>{item.text}</span>
          <small className="text-gray">{item.subText}</small>
        </div>
      </Link>
    </li>
  );
};

export default NavPath;
