import React from 'react';
import { useTranslation } from 'hooks';

const Logout: React.FC = () =>
{
  const t = useTranslation();
  return (
    <div>
      <h1>{t('Utloggad')}</h1>
      <p>{t('Du har nu blivit utloggad från Byggtjänst Studio')}</p>
    </div>
  );
};

export default Logout;
