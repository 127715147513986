import React from 'react';
import Modal from 'react-bootstrap/Modal';
import './NotificationModal.scss';
import { useNotificationStore } from 'store';

export enum Notification {
  Hidden,
  Success = 200,
  Unauthorized = 401,
  Forbidden = 403,
  ServerError = 500,
}

const SUCCESSMESSAGE = 'Det lyckades.';
const SERVERERRORMESSAGE = 'Något gick fel. Vänligen försök igen.';
const UNAUTHORIZEDMESSAGE = 'Behörighet saknas.';
const FORBIDDENMESSAGE = 'Behörighet saknas, kontakta projektägaren för hjälp.';

const NotificationIcon = (n: Notification | undefined): JSX.Element => {
  switch (n) {
    case Notification.Hidden:
      return <></>;

    case Notification.Success:
      return (
        <svg
          className="mt-5"
          width="51"
          height="39"
          viewBox="0 0 51 39"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M16.4995 30.9L4.59948 19L0.632812 22.9667L16.4995 38.8334L50.4995 4.83337L46.5328 0.866699L16.4995 30.9Z"
            fill="#008746"
          />
        </svg>
      );

    case Notification.Forbidden:
    case Notification.Unauthorized:
      return (
        <svg
          width="58"
          height="58"
          viewBox="0 0 58 58"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M29.0013 0.666504C13.3613 0.666504 0.667969 13.3598 0.667969 28.9998C0.667969 44.6398 13.3613 57.3332 29.0013 57.3332C44.6413 57.3332 57.3346 44.6398 57.3346 28.9998C57.3346 13.3598 44.6413 0.666504 29.0013 0.666504ZM31.8346 43.1665H26.168V37.4998H31.8346V43.1665ZM31.8346 31.8332H26.168V14.8332H31.8346V31.8332Z"
            fill="#F39313"
          />
        </svg>
      );

    case Notification.ServerError:
    default:
      return (
        <svg
          width="64"
          height="55"
          viewBox="0 0 64 55"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M0.832031 54.4998H63.1654L31.9987 0.666504L0.832031 54.4998ZM34.832 45.9998H29.1654V40.3332H34.832V45.9998ZM34.832 34.6665H29.1654V23.3332H34.832V34.6665Z"
            fill="#D51317"
          />
        </svg>
      );
  }
};

const NotificationMessage = (notification: Notification | undefined): string => {
  let notificationMessage = '';

  switch (notification) {
    case Notification.Success:
      notificationMessage = SUCCESSMESSAGE;
      break;

    case Notification.Unauthorized:
      notificationMessage = UNAUTHORIZEDMESSAGE;
      break;

    case Notification.Forbidden:
      notificationMessage = FORBIDDENMESSAGE;
      break;

    case Notification.Hidden:
      break;

    case Notification.ServerError:
    default:
      notificationMessage = SERVERERRORMESSAGE;
      break;
  }

  return notificationMessage;
};


const NotificationModal = (): JSX.Element => {

  const notifications = useNotificationStore();
  return (
    <Modal
      dialogClassName="notification-dialog"
      contentClassName="bg-white notification-modal"
      show={notifications.notification !== Notification.Hidden}
      onHide={() => notifications.removeNotification()}
      centered
      size={'sm'}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="text-center">
          {NotificationIcon(notifications.notification)}
          <div className="my-5 text-large">{NotificationMessage(notifications.notification)}</div>
        </div>
      </Modal.Body>
    </Modal>
  );
};


export default NotificationModal;
