export const queryKeys = {
    project: 'project',
    projects: 'projects',
    projectRoles: 'projectRoles',
    projectParticipants: 'projectParticipants',
    hasLicense: 'hasLicense',
    specifications: 'specifications',
    specificatonParticipants: 'specificatonParticipants'
}

export const staleTime = {
    thirtySeconds: 30000,
    oneMinute: 60000,
    infinity: Infinity
}
