import { IAppConfig } from 'IAppConfig';
import React from 'react';

export interface IAppConfigContext {
  config: IAppConfig;
}

const AppConfigContext = React.createContext<IAppConfigContext>({} as IAppConfigContext);

export default AppConfigContext;
