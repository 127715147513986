function useTranslation(): (phrase: string) => string {
  // Fetch current user
  // Fetch current user language

  return (phrase: string) => {
    // Access translation file/storage something by key
    // Return result of found

    return phrase;
  };
}

export default useTranslation;
