import React from 'react';
import Avatar from "./Avatar";
import './Avatar.scss';

interface AvatarProfileProps {
  avatarOptions: IAvatarOptions | undefined;
  className?: string;
  hiddenOnMobile?: boolean;
}

const AvatarProfile: React.FC<AvatarProfileProps> = ({
  avatarOptions: options,
  className,
  hiddenOnMobile,
}) => options?.participant ? (
  <div className={`avatar-profile mx-2 d-flex flex-row ${className || ''}`}>
    <Avatar
      inverted={options?.inverted}
      className={options?.className}
      participant={{
        firstName: options.participant.firstName,
        lastName: options.participant.lastName,
        userId: options.participant.userid,
      }}
    />
    <div className={`avatar-profile-text ms-2 ${options.participant?.email ? 'mt-1' : 'mt-2'} ${hiddenOnMobile ? 'd-none d-sm-flex' : 'd-flex'} flex-column`}> 
      <span>{options.participant?.firstName} {options.participant?.lastName}</span>
      { options.participant?.email && <span className="text-muted text-small">{options.participant?.email}</span> }
    </div>
  </div>
) : (
  <></>
);

export interface IAvatarOptions {
  participant: IAvatarParticipant | undefined;
  inverted?: boolean;
  className?: string;
}

export interface IAvatarParticipant {
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  userid?: string | null;
}

export default AvatarProfile;
