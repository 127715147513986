import React, { useEffect } from 'react'
import { useAppConfig, useUser } from '../hooks'
import {init} from '.'

export interface tagManagerEvent{
    action: string;
    category: string;
}

export const GoogleTagManager = () => {
    const { config } = useAppConfig();
    const profile = useUser();

    useEffect(() => {
        if (config && profile) {
            init(config.MEASUREMENT_ID, profile.userid)
        }
    }, [config, profile]);
    return <></>;
}