import { useApi } from 'hooks';
import { useQuery } from 'react-query';
import { queryKeys, staleTime } from 'react-query/constants';

const useSpecifications = () => {
  const api = useApi();
  const { data, isLoading } = useQuery([queryKeys.specifications], async () => {
    const res = await api.getFunctionalSpecificationsForUser();
    return res.data;
  }, {
    staleTime: staleTime.oneMinute
  });

  const specifications = data ?? [];
  return {
    specifications,
    isLoading
  }
}

export { useSpecifications };
