import useAppConfig from 'hooks/useAppConfig';

const useUrlFormatter = () =>
{
    const config = useAppConfig();

    const amaFunktion = (specificationId: string) =>
    {
        return `${config.config.AMASTUDIO_URL}/overview/${specificationId}`
    }

    return {
        amaFunktion
    }
}


export { useUrlFormatter };