import React from 'react';
import { Link } from 'react-router-dom';
import { Create } from '@material-ui/icons/';
import { ProjectViewModel } from 'types/IDataContracts';
import EditProjectModal from 'Component/ProjectForm/EditProjectModal';

const ProjectListItem: React.FC<ProjectListItemProps> = (
  props: ProjectListItemProps
) =>
{
  const { project } = props;
  const isAdmin = project.isAdmin;

  return (
    <li className="list-group-item project-list-item">
      <div className="d-flex justify-content-between align-items-center project-list-itemwrap">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex flex-column text-small projectnumber">
            <div>
              <span className="font-color-gray-4">Projektnummer</span>
            </div>
            <div>{project?.projectNumber ?? '-'}</div>
          </div>
          <Link
            className="text-medium font-weight-700"
            to={'/projekt/' + (project?.id || '#')}>
            {project?.name}
          </Link>
        </div>
        {isAdmin && (
          <EditProjectModal
            project={project}
            modalTriggerButton={
              <button className="btn btn-link edit-btn" type="button">
                <Create />
              </button>
            }
          />
        )}
      </div>
    </li>
  );
};

interface ProjectListItemProps
{
  project: ProjectViewModel;
}

export default ProjectListItem;
