import React, { useEffect, useContext } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { AuthContext } from 'Authentication/Auth';
import { signoutRedirectCallback } from 'Authentication/AuthUserManager';

const SignoutOidc: React.FC<RouteComponentProps> = () =>
{
  const history = useHistory();
  const authContext = useContext(AuthContext);

  useEffect(() =>
  {
    async function signoutAsync()
    {
      await signoutRedirectCallback();
      authContext.setUser(null);
      history.push('/');
    }
    void signoutAsync();
  }, [history]);

  return <div>Redirecting...</div>;
};

export default SignoutOidc;
