import React, { SetStateAction } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { ModalButtonProps } from './ModalButton';
import './Modal.scss';

const ModalWrapper: React.FC<ModalProps> = (props): JSX.Element => {
  const handleShow = () => {
    props.setShow(true);
  }

  return (
    <>
      {typeof props.buttonContent === 'string' ? (
        <Button className="btn btn-link" onClick={handleShow}>
          {props.buttonContent}
        </Button>
      ) : (
        React.cloneElement(props.buttonContent, {
          onClick: handleShow,
        })
      )}
      <Modal
        backdrop={props.backdrop ?? true}
        backdropClassName={props.backdropClassName}
        contentClassName="bg-white"
        name={props.name}
        scrollable={props.scrollable ? true : props.scrollable}
        id={props.id}
        className={props.className}
        show={props.show}
        onHide={() => props.setShow(false)}
        centered={true}>
        <Modal.Header className="mb-3" closeButton>
          {props.header && React.cloneElement(props.header)}
          {props.title && (
            <Modal.Title className="mt-2">{props.title}</Modal.Title>
          )}
        </Modal.Header>
        {((props.header || props.title) && props.children) && <hr className="mx-auto" />}
        <Modal.Body>{props.children}</Modal.Body>
      </Modal>
    </>
  );
};

interface ModalProps {
  id?: string;
  name?: string;
  className?: string;
  buttonContent: string | React.ReactElement;
  title?: string;
  header?: React.ReactElement;
  buttons?: ModalButtonProps[];
  show: boolean;
  scrollable?: boolean;
  backdrop?: boolean
  backdropClassName?: string

  setShow: React.Dispatch<SetStateAction<boolean>>;
}

export default ModalWrapper;
